import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import moment from "moment";
import { StateService } from "../../services/state.service";
import { CatExpertRouterLink } from "../../app-routing-enum";
import { HttpService } from "../../services/http.service";
import { MessageService } from "primeng/api";
import { TreeNode } from "primeng/api";
import { TreeData } from "../../core/constants";

@Component({
  selector: "app-pho-sidebar-form",
  templateUrl: "./pho-sidebar-form.component.html",
  styleUrls: ["./pho-sidebar-form.component.scss"],
})
export class PhoSidebarFormComponent implements OnInit, OnChanges {
  @Input() nestingSideBarForm: any = [];
  @Input() nestingFormDefault: any = {};
  @Input() formAccessType: boolean = true;
  @Input() isVotingClosed: boolean = false;
  @Input() accessType: any;
  @Output() action = new EventEmitter<any>();
  @Output() actionChange = new EventEmitter<any>();
  @Output() actionViewGraph = new EventEmitter<any>();
  @Output() actionHelp = new EventEmitter<any>();
  @Output() nodeSelected = new EventEmitter<any>();
  @Output() actionEditUpload = new EventEmitter<any>();
  inputForm!: FormGroup;
  advancedParamsShow: boolean = false;
  activeAccord: any = 0;
  ownersList: any[] = [];
  activeDelistBtn: boolean = false;
  activeKeepBtn: boolean = false;
  activeConsultBtn : boolean = false;
  lockAssortment: boolean = false;
  expId!: any;
  users: any[] = [];
  treeOptions!: TreeNode[];
  selectedNodes: any;
  selectedPlanOfaction!: TreeNode;
  selectedNodeLabel: string = "";
  isCustomInput: boolean = false;
  customValue: string = "";
  isCustom: boolean = false;
  skuDetails: any;
  suggestedUserList:any[]=[];

  customOptionValue: string = ""; // Store the custom value

  constructor(
    private formBuilder: FormBuilder,
    public stateService: StateService,
    private httpService: HttpService,
    private messageService: MessageService
  ) {
    // syncing assortment steps lock value
    this.stateService.getAssortmentLock.subscribe((newValue: any) => {
      this.setAssortmentStepsLock();
    });
  }

  ngOnInit(): void {
    this.expId = sessionStorage.getItem("expId");
    this.skuDetails = JSON.parse(localStorage.getItem("sprDetails")!);
    // locking assortment steps after initiate voting
    this.setAssortmentStepsLock();

    this.treeOptions = TreeData;
    this.setSelectedPlanOfAction();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.setForm();
    }
  }

  formatLabel(value: number | null): string {
    // Always display the thumb label
    return value !== null ? value.toString() : "";
  }

  setForm() {
    this.inputForm = this.formBuilder.group({});
    if (this.nestingSideBarForm?.arrayFormFlag) {
      this.nestingSideBarForm?.accordionList?.map((fieldArray: any) => {
        fieldArray?.formField?.map((field: any) => {
          this.addFormField(field);
        });
      });
    } else {
      this.nestingSideBarForm?.formField?.map((field: any) => {
        this.addFormField(field);
      });
    }
    if (!this.nestingSideBarForm?.formAccessType) {
      this.inputForm.disable();
    }
    this.activeKeepBtn =
      this.nestingFormDefault["final_verdict"] == "Consult" ? true : false;
    this.activeDelistBtn =
      this.nestingFormDefault["final_verdict"] == "Delist" ? true : false;
  }

  addFormField(field: any) {
    if (field.model) {
      let validators = [];
      let disabledFlag = this.isVotingClosed || this.accessType === 'read';
      if (field.isRequired) {
        validators.push(Validators.required);
      }
      if (field.pattern) {
        validators.push(Validators.pattern(field.pattern));
      }
      if (field?.min) {
        validators.push(Validators.min(field.min));
      }
      if (field?.max) {
        validators.push(Validators.max(field.max));
      }

      this.inputForm.addControl(
        field.model,
        new FormControl(
          {
            value: this.nestingSideBarForm?.arrayFormFlag
              ? this.nestingFormDefault[field.model] || 0
              : this.nestingFormDefault[field.model] || "",
            disabled: disabledFlag,
          },
          validators
        )
      );
    }
  }

  // Function for set the clustering form data
  setClusterFormValue() {
    this.inputForm.patchValue({
      feature_list: this.nestingFormDefault["feature_list"],
    });
    this.inputForm.patchValue({
      number_of_cluster: this.nestingFormDefault["number_of_cluster"],
    });
  }

  getClusteringItem(item: any) {
    this.inputForm.patchValue({
      number_of_cluster: item,
    });

    // setting keep/delist buttons active
    this.activeKeepBtn =
      this.nestingFormDefault["final_verdict"] == "Consult" ? true : false;
    this.activeDelistBtn =
      this.nestingFormDefault["final_verdict"] == "Delist" ? true : false;
  }

  onBlurEvent() {
    let data = {};
    let checkStatesStatus = this.stateService.checkStatesFlag(
      CatExpertRouterLink.SkuFunnel
    );
    if (checkStatesStatus) {
      data = {
        key_change: "feature_list",
        check_status: checkStatesStatus,
        input_form: this.inputForm.getRawValue(),
      };
      this.actionChange.emit(data);
    } else {
      if (!this.inputForm.controls["feature_list"].valid) {
        this.inputForm.markAllAsTouched();
        return;
      }
      data = {
        input_form: this.inputForm.getRawValue(),
      };
      this.actionChange.emit(data);
      this.resetClusterFormValue();
    }
  }

  resetClusterFormValue() {
    this.inputForm.controls["number_of_cluster"].setValue("");
    this.nestingFormDefault["number_of_cluster"] = [];
    this.nestingSideBarForm["clusterRecommendation"] = [];
  }

  formAction(action: any) {
    if (action === "submit") {
      // Update the form control value properly using patchValue
      if (this.inputForm.value.keep_delist === "") {
        this.inputForm.patchValue({
          keep_delist: this.skuDetails.final_verdict,
        });
      }

      if (this.inputForm.valid) {
        const formData = this.inputForm.getRawValue();
      
        this.suggestedUserList = formData?.owner?.map((owner:any) => owner.email);

        // Handle the 'Others' option for the plan_of_action
        if (formData?.plan_of_action) {
          formData.plan_of_action =
            formData.plan_of_action.label === "Others"
              ? this.customOptionValue
              : formData.plan_of_action.label;
        }

        this.action.emit(formData);
      } else {
        this.inputForm.markAllAsTouched();
      }
    }
  }

  clickViewGraph() {
    this.actionViewGraph.emit();
  }

  actionHelpModal() {
    this.actionHelp.emit();
  }

  toggleAdvancedParams() {
    this.advancedParamsShow = !this.advancedParamsShow;
  }

  setActiveAccod(index: any) {
    if (this.activeAccord !== index) {
      this.activeAccord = index;
    } else {
      this.activeAccord = -1;
    }
  }
  onExpiryDateTimeSelect(event: any, type: string) {
    let dateTime = moment(event).format("L");
    this.inputForm.patchValue({ [type]: dateTime });
  }

  getCtrlValue(ctrlName: any) {
    return this.inputForm.controls[ctrlName].value;
  }

  onSearchOwners(event: any) {
    this.ownersList = [
      "bhaskararao.gummidi-ext@ab-inbev.com",
      "ashok.kumar-ext3@ab-inbev.com",
    ];
    if (event?.length) {
      this.inputForm.patchValue({ owner: event });
    }
  }

  onKeepDelist(event: any, value: string) {
    this.activeKeepBtn = value == "Consult" ? true : false;
    this.activeDelistBtn = value == "Delist" ? true : false;
    this.inputForm.patchValue({ keep_delist: value });
  }

  setAssortmentStepsLock() {
    const lockAssortmentSteps = JSON.parse(
      sessionStorage.getItem("lockAssortment")!
    );
    if (lockAssortmentSteps) {
      this.lockAssortment = lockAssortmentSteps[this.expId!];
    }
  }

  getUsersOnType(event: any) {
    const apiUrl = `${this.stateService.apiList.experiments.phoSearchUser}?name=${event}`;
    this.httpService.getMethod(apiUrl).subscribe({
      next: (response: any) => {
        this.users = response;
      },
      error: (err: any) => {
        if (err.status === 400) {
          this.messageService.add({
            key: "w",
            severity: "warn",
            summary: "Something went wrong!",
            detail: err?.error?.message,
          });
        } else if (err.status !== 401) {
          this.messageService.clear();
          this.messageService.add({
            key: "c",
            severity: "warn",
            summary: err?.error?.message,
            detail: err?.error?.traceback,
          });
        }
      },
    });
  }

  onOwnerSelect(event: any, model: any) {
    this.inputForm.patchValue({ [model]: event });
  }

  onNodeClick(event: any) {
    const node = event.node;

    const selectedValue = node.data;
    this.collapseAllNodes(this.treeOptions);

    node.expanded = true;
  }

  // Helper method to collapse all nodes
  collapseAllNodes(nodes: any[]) {
    for (const node of nodes) {
      node.expanded = false;
      if (node.children && node.children.length > 0) {
        this.collapseAllNodes(node.children);
      }
    }
  }

  selectedNode(event: any) {
    this.isCustom = false;
    this.selectedNodeLabel = event.node.label;

    if (event.node.label === "Others") {
      this.isCustomInput = true;
      this.selectedNodes = event.node.data;
    } else {
      this.isCustomInput = false;
      this.selectedNodes = event.node.data;
    }
  }

  onCustomOptionChange(event: any) {
    this.customOptionValue = event.target.value;
  }

  setSelectedPlanOfAction() {
    let isPlanOfActionFound = false;

    // Loop through treeOptions and their children to find the matching label
    for (let option of this.treeOptions) {
      if (option.children) {
        for (let child of option.children) {
          if (child.label === this.skuDetails?.plan_of_action) {
            this.selectedNodes = [child]; // Wrap the selected node in an array
            isPlanOfActionFound = true;
            break;
          }
        }
      }
      if (isPlanOfActionFound) {
        break;
      }
    }

    if (!isPlanOfActionFound && this.skuDetails?.plan_of_action != null) {
      const othersNode = this.treeOptions.find(
        (node) => node.label === "Others"
      );
      if (othersNode) {
        // Creating a shallow copy of the 'Others' node
        const copiedNode = { ...othersNode };

        // Updating the label in the copied node
        copiedNode.label = this.skuDetails.plan_of_action;

        // Set the copied node in the selectedNodes array without modifying treeOptions
        this.selectedNodes = [copiedNode]; // Use the updated copy instead of the original
      }
    }

    if (this.skuDetails?.plan_of_action == null) {
      this.selectedNodes = null;
    }
  }

  onLabelClick(event: MouseEvent, node: any) {
    // If 'Others' is clicked, show the custom input
    if (node.label === "Others") {
      this.isCustomInput = true;
    } else {
      this.isCustomInput = false; // Hide custom input if any other node is clicked
    }

    // Toggle expand/collapse for non-selectable nodes
    if (!node.selectable) {
      node.expanded = !node.expanded;
    }
  }
}
