import {
  PlanResponseModel,
  ProductsResponseModel,
  ProductsLeftoutResponseModel,
  EmptyGrid,
} from "./plano.types";

export const SEGMENT_STYLE_COLORS: { [key: string]: string } = {
  CORE: "#f8b4b4",
  "CORE+": "#ff8a8a",
  VALUE: "#ab8212",
  PREMIUM: "#e6f514",
  SELTZER: "#778410",
  SODA: "#b0b842",
  EMPTY: "#8e8e8e",
  CRAFT: "#fbeec6",
  IMPORT: "#ee5959",
  PILSNER: "#7d66f0",
  LIGHTBLOND: "#08c4b7",
  RTD: "#a4f4b1",
  LAGER: "#61ea6a",
  PALE: "#11e820",
  extra7: "#118d2a",
  extra8: "#8edc18",
  extra9: "#e5ee72",
  extra10: "#e70404",
  extra11: "#5f4f4f",
  extra12: "#7e4444",
  extra14: "#c662a1",
  extra15: "#e70488",
  extra16: "#e609f6",
  extra17: "#fd96f4",
  extra18: "#92079c",
  extra19: "#b1b4fb",
  extra20: "#432cf2",
  extra21: "#20109e",
  extra22: "#a4ebf4",
  extra23: "#49dcdf",
};

export const SEGMENT_STYLE_COLORS_ARRAY = [
  "#f8b4b4",
  "#ff8a8a",
  "#ab8212",
  "#e6f514",
  "#778410",
  "#b0b842",
  "#fbeec6",
  "#ee5959",
  "#7d66f0",
  "#08c4b7",
  "#a4f4b1",
  "#61ea6a",
  "#11e820",
  "#118d2a",
  "#8edc18",
  "#e5ee72",
  "#e70404",
  "#5f4f4f",
  "#7e4444",
  "#c662a1",
  "#e70488",
  "#e609f6",
  "#fd96f4",
  "#92079c",
  "#b1b4fb",
  "#432cf2",
  "#20109e",
  "#a4ebf4",
  "#49dcdf",
  "#E68660",
  "#7B0206",
  "#A4E174",
  "#308716",
  "#C9F9FB",
  "#017DB2",
  "#FDF7CA",
  "#DDB500",
  "#F6C19F",
  "#F696BD",
  "#7B026F",
  "#E6CA60",
  "#936A03",
  "#A8A8F5",
  "#2840D8",
  "#33B73A",
  "#CA5AEC",
  "#77E3D4",
  "#187E8B",
  "#6DEB8D",
  "#904d4d",
  "#8FD2C2",
  "#DDAA9F",
  "#7B383E",
  "#F6F646",
  "#DDAA9F",
  "#24595B",
  "#f00ce6",
  "#BC807A",
  "#EEAB9D",
  "#F1C583",
  "#7A3510",
  "#43B867",
  "#F6B9F8",
  "#CC96E4",
  "#4E9D6D",
  "#8094b8",
];

export const DUMMY_PLAN_RESPONSE: any = {
  id: "502c48a6-7a08-4915-9bfb-a4da271c4c34",
  name: "Plan 1",
  state: "product_allocation",
  market_shares: {
    revenue: {
      ptc_segment: [
        {
          label: "VALUE",
          market_share: 30.02,
          user_allocated_share: 30.0,
        },
        {
          label: "CRAFT",
          market_share: 16.57,
          user_allocated_share: 16.5,
        },
        {
          label: "SODA",
          market_share: 15.11,
          user_allocated_share: 15.1,
        },
        {
          label: "CORE",
          market_share: 7.69,
          user_allocated_share: 7.7,
        },
        {
          label: "CIDER",
          market_share: 6.64,
          user_allocated_share: 6.7,
        },
        {
          label: "IMPORT",
          market_share: 4.95,
          user_allocated_share: 4.9,
        },
        {
          label: "SELTZER",
          market_share: 4.75,
          user_allocated_share: 4.8,
        },
        {
          label: "TEA",
          market_share: 4.03,
          user_allocated_share: 4.0,
        },
        {
          label: "COOLER",
          market_share: 3.97,
          user_allocated_share: 4.0,
        },
        {
          label: "CORE+",
          market_share: 2.7,
          user_allocated_share: 2.7,
        },
        {
          label: "COCKTAIL",
          market_share: 2.54,
          user_allocated_share: 2.5,
        },
        {
          label: "CAESAR",
          market_share: 1.01,
          user_allocated_share: 1.0,
        },
      ],
      style: [
        {
          label: "VALUE",
          market_share: 30.02,
          user_allocated_share: 30.02,
        },
        {
          label: "CRAFT",
          market_share: 16.57,
          user_allocated_share: 16.57,
        },
        {
          label: "SODA",
          market_share: 15.11,
          user_allocated_share: 15.11,
        },
        {
          label: "CORE",
          market_share: 7.69,
          user_allocated_share: 7.69,
        },
        {
          label: "CIDER",
          market_share: 6.64,
          user_allocated_share: 6.64,
        },
        {
          label: "IMPORT",
          market_share: 4.95,
          user_allocated_share: 4.95,
        },
        {
          label: "SELTZER",
          market_share: 4.75,
          user_allocated_share: 4.75,
        },
        {
          label: "TEA",
          market_share: 4.03,
          user_allocated_share: 4.03,
        },
        {
          label: "COOLER",
          market_share: 3.97,
          user_allocated_share: 3.97,
        },
        {
          label: "CORE+",
          market_share: 2.7,
          user_allocated_share: 2.7,
        },
        {
          label: "COCKTAIL",
          market_share: 2.54,
          user_allocated_share: 2.54,
        },
        {
          label: "CAESAR",
          market_share: 1.01,
          user_allocated_share: 1.01,
        },
      ],
    },
  },
  rules: [
    {
      title: "sequence_of_product_allocation",
      params: [
        { title: "traffic_flow", type: "string", value: "left_to_right" },
      ],
    },
  ],
  task_status: "completed",
  updated_at: "2023-02-15T06:09:03.750000",
  category: "ptc_segment",
  experiment_id: "40f861b7-d6cd-43f8-9e11-3f5abe7b450c",
  state_status: "completed",
  grid: {
    length: 250.0,
    height: 72.0,
    depth: 24.0,
    unit: "inch",
    dos: 7.0,
    doors: [
      {
        label: "Door 1",
        position: {
          x: {
            start: 0,
            end: 50,
          },
        },
        shelves: [
          {
            depth: 24,
            position: {
              y: {
                start: 0,
                end: 18,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 0,
                    end: 50,
                  },
                },
                label: "VALUE",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 18,
                end: 36,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 0,
                    end: 50,
                  },
                },
                label: "VALUE",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 36,
                end: 54,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 0,
                    end: 50,
                  },
                },
                label: "VALUE",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 54,
                end: 72,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 0,
                    end: 50,
                  },
                },
                label: "VALUE",
              },
            ],
          },
        ],
        depth: 24,
      },
      {
        label: "Door 2",
        position: {
          x: {
            start: 50,
            end: 100,
          },
        },
        shelves: [
          {
            depth: 24,
            position: {
              y: {
                start: 0,
                end: 18,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 50,
                    end: 100,
                  },
                },
                label: "VALUE",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 18,
                end: 36,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 50,
                    end: 100,
                  },
                },
                label: "VALUE",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 36,
                end: 54,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 50,
                    end: 50,
                  },
                },
                label: "VALUE",
              },
              {
                position: {
                  x: {
                    start: 50,
                    end: 100,
                  },
                },
                label: "CRAFT",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 54,
                end: 72,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 50,
                    end: 100,
                  },
                },
                label: "CRAFT",
              },
            ],
          },
        ],
        depth: 24,
      },
      {
        label: "Door 3",
        position: {
          x: {
            start: 100,
            end: 150,
          },
        },
        shelves: [
          {
            depth: 24,
            position: {
              y: {
                start: 0,
                end: 18,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 100,
                    end: 150,
                  },
                },
                label: "CRAFT",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 18,
                end: 36,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 100,
                    end: 115,
                  },
                },
                label: "CRAFT",
              },
              {
                position: {
                  x: {
                    start: 115,
                    end: 150,
                  },
                },
                label: "SODA",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 36,
                end: 54,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 100,
                    end: 150,
                  },
                },
                label: "SODA",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 54,
                end: 72,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 100,
                    end: 150,
                  },
                },
                label: "SODA",
              },
            ],
          },
        ],
        depth: 24,
      },
      {
        label: "Door 4",
        position: {
          x: {
            start: 150,
            end: 200,
          },
        },
        shelves: [
          {
            depth: 24,
            position: {
              y: {
                start: 0,
                end: 18,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 150,
                    end: 166,
                  },
                },
                label: "SODA",
              },
              {
                position: {
                  x: {
                    start: 166,
                    end: 200,
                  },
                },
                label: "CORE",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 18,
                end: 36,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 150,
                    end: 193,
                  },
                },
                label: "CORE",
              },
              {
                position: {
                  x: {
                    start: 193,
                    end: 200,
                  },
                },
                label: "CIDER",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 36,
                end: 54,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 150,
                    end: 200,
                  },
                },
                label: "CIDER",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 54,
                end: 72,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 150,
                    end: 160,
                  },
                },
                label: "CIDER",
              },
              {
                position: {
                  x: {
                    start: 160,
                    end: 200,
                  },
                },
                label: "IMPORT",
              },
            ],
          },
        ],
        depth: 24,
      },
      {
        label: "Door 5",
        position: {
          x: {
            start: 200,
            end: 250,
          },
        },
        shelves: [
          {
            depth: 24,
            position: {
              y: {
                start: 0,
                end: 18,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 200,
                    end: 209,
                  },
                },
                label: "IMPORT",
              },
              {
                position: {
                  x: {
                    start: 209,
                    end: 250,
                  },
                },
                label: "PROMOTION",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 18,
                end: 36,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 200,
                    end: 207,
                  },
                },
                label: "SELTZER",
              },
              {
                position: {
                  x: {
                    start: 207,
                    end: 247,
                  },
                },
                label: "TEA",
              },
              {
                position: {
                  x: {
                    start: 247,
                    end: 250,
                  },
                },
                label: "PROMOTION",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 36,
                end: 54,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 200,
                    end: 237,
                  },
                },
                label: "COOLER",
              },
              {
                position: {
                  x: {
                    start: 237,
                    end: 250,
                  },
                },
                label: "CORE+",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 54,
                end: 72,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 200,
                    end: 214,
                  },
                },
                label: "CORE+",
              },
              {
                position: {
                  x: {
                    start: 214,
                    end: 239,
                  },
                },
                label: "PROMOTION",
              },
              {
                position: {
                  x: {
                    start: 239,
                    end: 249,
                  },
                },
                label: "CAESAR",
              },
            ],
          },
        ],
        depth: 24,
      },
    ],
  },
  task_id: "f6826aaa-79bb-4460-97ae-7094e4ca3551",
  created_at: "2023-01-18T08:17:56.267000",
};

export const GRID_RESPONSE: any = {
  grid: {
    length: 250.0,
    height: 72.0,
    depth: 24.0,
    unit: "inch",
    dos: 7.0,
    doors: [
      {
        label: "Door 1",
        position: {
          x: {
            start: 0,
            end: 50,
          },
        },
        shelves: [
          {
            depth: 24,
            position: {
              y: {
                start: 0,
                end: 18,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 0,
                    end: 25,
                  },
                },
                label: "VALUE",
              },
              {
                position: {
                  x: {
                    start: 25,
                    end: 50,
                  },
                },
                label: "PROMOTION",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 18,
                end: 36,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 0,
                    end: 50,
                  },
                },
                label: "VALUE",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 36,
                end: 54,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 0,
                    end: 50,
                  },
                },
                label: "VALUE",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 54,
                end: 72,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 0,
                    end: 50,
                  },
                },
                label: "VALUE",
              },
            ],
          },
        ],
        depth: 24,
      },
      {
        label: "Door 2",
        position: {
          x: {
            start: 50,
            end: 100,
          },
        },
        shelves: [
          {
            depth: 24,
            position: {
              y: {
                start: 0,
                end: 18,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 50,
                    end: 100,
                  },
                },
                label: "VALUE",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 18,
                end: 36,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 50,
                    end: 100,
                  },
                },
                label: "VALUE",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 36,
                end: 54,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 50,
                    end: 80,
                  },
                },
                label: "VALUE",
              },
              {
                position: {
                  x: {
                    start: 80,
                    end: 100,
                  },
                },
                label: "PROMOTION",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 54,
                end: 72,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 50,
                    end: 60,
                  },
                },
                label: "CRAFT",
              },
              {
                position: {
                  x: {
                    start: 60,
                    end: 80,
                  },
                },
                label: "PROMOTION",
              },
              {
                position: {
                  x: {
                    start: 80,
                    end: 100,
                  },
                },
                label: "CRAFT",
              },
            ],
          },
        ],
        depth: 24,
      },
      {
        label: "Door 3",
        position: {
          x: {
            start: 100,
            end: 150,
          },
        },
        shelves: [
          {
            depth: 24,
            position: {
              y: {
                start: 0,
                end: 18,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 100,
                    end: 150,
                  },
                },
                label: "CRAFT",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 18,
                end: 36,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 100,
                    end: 115,
                  },
                },
                label: "CRAFT",
              },
              {
                position: {
                  x: {
                    start: 115,
                    end: 150,
                  },
                },
                label: "SODA",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 36,
                end: 54,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 100,
                    end: 150,
                  },
                },
                label: "SODA",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 54,
                end: 72,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 100,
                    end: 150,
                  },
                },
                label: "SODA",
              },
            ],
          },
        ],
        depth: 24,
      },
      {
        label: "Door 4",
        position: {
          x: {
            start: 150,
            end: 200,
          },
        },
        shelves: [
          {
            depth: 24,
            position: {
              y: {
                start: 0,
                end: 18,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 150,
                    end: 166,
                  },
                },
                label: "SODA",
              },
              {
                position: {
                  x: {
                    start: 166,
                    end: 200,
                  },
                },
                label: "CORE",
              },
            ],
          },

          {
            depth: 24,
            position: {
              y: {
                start: 18,
                end: 36,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 150,
                    end: 193,
                  },
                },
                label: "CORE",
              },
              {
                position: {
                  x: {
                    start: 193,
                    end: 200,
                  },
                },
                label: "CIDER",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 36,
                end: 54,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 150,
                    end: 200,
                  },
                },
                label: "CIDER",
              },
            ],
          },
          {
            depth: 24,
            position: {
              y: {
                start: 54,
                end: 72,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 150,
                    end: 160,
                  },
                },
                label: "CIDER",
              },
              {
                position: {
                  x: {
                    start: 160,
                    end: 200,
                  },
                },
                label: "IMPORT",
              },
            ],
          },
        ],
        depth: 24,
      },
      {
        label: "Door 5",
        position: {
          x: {
            start: 200,
            end: 250,
          },
        },
        shelves: [
          {
            depth: 24,
            position: {
              y: {
                start: 0,
                end: 18,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 200,
                    end: 209,
                  },
                },
                label: "IMPORT",
              },
              {
                position: {
                  x: {
                    start: 209,
                    end: 250,
                  },
                },
                label: "SELTZER",
              },
            ],
          },

          {
            depth: 24,
            position: {
              y: {
                start: 18,
                end: 36,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 200,
                    end: 207,
                  },
                },
                label: "SELTZER",
              },
              {
                position: {
                  x: {
                    start: 207,
                    end: 247,
                  },
                },
                label: "TEA",
              },
              {
                position: {
                  x: {
                    start: 247,
                    end: 250,
                  },
                },
                label: "COOLER",
              },
            ],
          },

          {
            depth: 24,
            position: {
              y: {
                start: 36,
                end: 54,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 200,
                    end: 237,
                  },
                },
                label: "COOLER",
              },
              {
                position: {
                  x: {
                    start: 237,
                    end: 250,
                  },
                },
                label: "CORE+",
              },
            ],
          },

          {
            depth: 24,
            position: {
              y: {
                start: 54,
                end: 72,
              },
            },
            allocations: [
              {
                position: {
                  x: {
                    start: 200,
                    end: 214,
                  },
                },
                label: "CORE+",
              },
              {
                position: {
                  x: {
                    start: 214,
                    end: 239,
                  },
                },
                label: "COCKTAIL",
              },
              {
                position: {
                  x: {
                    start: 239,
                    end: 249,
                  },
                },
                label: "CAESAR",
              },
            ],
          },
        ],
        depth: 24,
      },
    ],
  },
};

export const LEFTOUT_PRODUCTS: any = {
  products: [
    {
      product_id: "234c6e71-cd57-4fa5-b9ef-bb606d0c201a",
      experiment_product_id: "04d0c23a-7caf-427c-8743-6059e2c61316",
      sku: "62699017037",
      sku_detail: "LONETREE AUTHENTIC DRY CIDER 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.020660377358491",
      manufacturer: "Northam Brewery",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "63d60b09-6431-4893-8ff7-d1e9c0112406",
      experiment_product_id: "090fc7b7-87cb-4222-9fb1-b39a1c31fdf1",
      sku: "6280555892",
      sku_detail: "NUTRL VODKA SODA GRAPEFT 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.016343283582090",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "42b47182-c3b6-4f2d-96e5-d5753ff51349",
      experiment_product_id: "14d35de7-0b23-4a71-9757-427733837cb2",
      sku: "6206712137",
      sku_detail: "Alexander Keith's 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "CORE+",
      style: "CORE+",
      dos_multiplier: "0.912500000000000",
      manufacturer: "Labatt",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "b47c3e7a-e346-4563-bf7b-1ebd4674ee6f",
      experiment_product_id: "14d9ee59-ce65-4432-a96e-2f5b3d38933a",
      sku: "7793051051",
      sku_detail: "OKANAGAN PREM APL CIDER EXCRSP 4/500C",
      width: "4.3",
      height: "12.0",
      depth: "4.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.008111111111111",
      manufacturer: "Labatt",
      pack_quantity: 1,
      pack_size: 2000,
    },
    {
      product_id: "76178c18-317f-4c21-9055-d87372ffbdea",
      experiment_product_id: "16bc3f4b-f166-412f-9a32-c2d54718dfee",
      sku: "33248199",
      sku_detail: "Whistler Chestnut Ale 6/330B",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.312857142857143",
      manufacturer: "Whistler Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "9a9a83c3-4e63-4fd7-a4ec-72d713e730b0",
      experiment_product_id: "1767acbb-1219-461c-9aa5-f790632a98cf",
      sku: "62067382192",
      sku_detail: "Stella Artois 6/330C",
      width: "7.0",
      height: "5.9",
      depth: "4.7",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 330,
    },
    {
      product_id: "e6d2ad9e-6881-4615-883d-ed85ed94f4d0",
      experiment_product_id: "17b018c5-ce70-4c56-8b63-75cfeba16d81",
      sku: "66142900013",
      sku_detail: "Factory Smugglers Trail Flaskers Ale 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "24.0",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.486666666666667",
      manufacturer: "Craft Collective Brewery",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "05cba353-fadc-45fb-a5fa-ced82546c8b3",
      experiment_product_id: "1b7efcc5-5e7f-49f2-96e1-004657f077f0",
      sku: "84340300047",
      sku_detail: "Nutrl Juicd Blood 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "1e7b855e-4742-449c-85c7-8737bd4884cc",
      experiment_product_id: "1bdb3c48-cbcf-4d7c-a8be-bbb081d2ae1c",
      sku: "7793051011",
      sku_detail: "OKAN EXTRA GLACIER BERRY CIDER 4/500C",
      width: "4.3",
      height: "12.0",
      depth: "4.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.012586206896552",
      manufacturer: "Labatt",
      pack_quantity: 1,
      pack_size: 2000,
    },
    {
      product_id: "3a1c32ce-ff73-439a-8852-65c1969d5af1",
      experiment_product_id: "1c3e4c14-0966-4f32-8eb5-e11f0d888d23",
      sku: "7745580014",
      sku_detail: "Other Canterbury 6/0.355 Can",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.182500000000000",
      manufacturer: "Pacific Western",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "4c545581-f91e-4fda-87eb-17d035d18b4c",
      experiment_product_id: "1df6aa77-86a1-46c3-a06c-a8a2a8a10ede",
      sku: "620671874",
      sku_detail: "Okanagan Premium Pear 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "b7984ff2-618a-4100-ac46-0d81777c2bfe",
      experiment_product_id: "222c0c16-da4f-434a-999c-f2663aa002b5",
      sku: "62699035992",
      sku_detail: "Brewhall Azedo Tropical Fruit Sour 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.292000000000000",
      manufacturer: "Brewhall Beer",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "7ba26469-e5ef-4d55-ba7e-8148a332a32d",
      experiment_product_id: "24245bbc-809b-47db-95b7-898569f0f09b",
      sku: "63601690",
      sku_detail: "MIKE'S HARD WHITE FREEZE 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COOLER",
      style: "COOLER",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "bec1b135-f9e1-48dd-8ba5-e432acf4f0bf",
      experiment_product_id: "25008009-8379-4398-add1-47d415c17716",
      sku: "81353540",
      sku_detail: "Pacific Western Pilsner 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.208571428571429",
      manufacturer: "Pacific Western Brewing",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "e3aa054e-f155-48aa-b4a7-790ffd862a69",
      experiment_product_id: "253865cb-0fc2-4da7-8917-24d4c952528b",
      sku: "92318740",
      sku_detail: "Mikes Hard Iced Tea 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "TEA",
      style: "TEA",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "574aaeaa-a838-4318-b376-e1daa14c0ded",
      experiment_product_id: "25be4e96-72a4-40ef-a310-db15941c126b",
      sku: "77455800135",
      sku_detail: "Pacific Western Pilsner 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.116489361702128",
      manufacturer: "Pacific Western",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "fbe68643-2b22-4f4c-9fa6-b8f5fffd8af4",
      experiment_product_id: "271b6522-85b1-47d0-a37e-f97d6d32cdfb",
      sku: "14043244",
      sku_detail: "MAD JACK MIXER 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.312857142857143",
      manufacturer: "Molson",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "dad9c0d2-b0e8-47f8-8f41-108dbb74f9f2",
      experiment_product_id: "297f1a93-55fc-42fe-9f8d-d09ad178f8ac",
      sku: "3980000000000",
      sku_detail: "Lucky Extra 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.486666666666667",
      manufacturer: "Labatt",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "72a8a9c9-b8a5-4ad1-b747-26e03648dc7b",
      experiment_product_id: "29bd5b93-841e-43cd-97d3-965600cbf22e",
      sku: "6206737445",
      sku_detail: "KOKANEE 8/355C",
      width: "7.9",
      height: "4.9",
      depth: "7.3",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "7a3f17ef-f929-4a49-b464-4bddccdef59a",
      experiment_product_id: "2b21519b-da43-4b0c-8333-4e7d50ee55f1",
      sku: "14182923",
      sku_detail: "Driftwood Brewery Fat Tug IPA 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.336923076923077",
      manufacturer: "Driftwood Brewing",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "a87871f6-6aa0-4181-aee7-31366cefc9b4",
      experiment_product_id: "2b2b0c57-f9af-4eee-bd45-24618b6d87e1",
      sku: "94739746",
      sku_detail: "MIKES BLUE FREEZE 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COOLER",
      style: "COOLER",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "3603eff4-7e36-4d80-9909-e62526dd18a9",
      experiment_product_id: "2b999402-a1c4-4ec3-8a0c-2a3386c9cc37",
      sku: "104359265",
      sku_detail: "Strathcona New England IPA 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.063478260869565",
      manufacturer: "Strathcona Brewery",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "a1a2ad23-17eb-46b5-aa86-abaa6353426d",
      experiment_product_id: "2fb19280-a3c4-49df-a80e-1825e0b012a1",
      sku: "62763200017",
      sku_detail: "R and B Brewing Dude Chilling Pale Ale 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "18.0",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.365000000000000",
      manufacturer: "R & B Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "2afea7a5-416e-4132-bd63-5ee131fcc94a",
      experiment_product_id: "3122cbd3-0618-42e7-8446-1ff2476b0183",
      sku: "65247206660",
      sku_detail: "Squish Lemonade Hard Seltzer 12/355C",
      width: "9.3",
      height: "6.3",
      depth: "6.9",
      mapping_count: 0,
      segment: "SELTZER",
      style: "SELTZER",
      dos_multiplier: "0.257647058823529",
      manufacturer: "Parallel 49 Brewing",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "503b923f-d16c-4007-a116-f72d03a78b58",
      experiment_product_id: "325adb7c-5acc-4abf-a55c-a5b716fdf954",
      sku: "6206712145",
      sku_detail: "Alexander Keith's 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "CORE+",
      style: "CORE+",
      dos_multiplier: "0.486666666666667",
      manufacturer: "Labatt",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "16b2efbf-4c26-421b-bf73-1ab21308a0a1",
      experiment_product_id: "34c645c0-888b-4ef5-9500-287077d72f22",
      sku: "102675217",
      sku_detail: "Fuggles & warlock Plum Sour 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.438000000000000",
      manufacturer: "Fuggles & Warlock Craftworks",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "aa3cebb8-8d7e-481c-8b39-67ccfce0b731",
      experiment_product_id: "365ad991-87c0-40c6-98aa-071c00267517",
      sku: "62699035986",
      sku_detail: "Brewhall Neon Lights Pale Ale 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.243333333333333",
      manufacturer: "Brewhall Beer",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "30eb50d1-881c-44b0-8185-36b653ee7d07",
      experiment_product_id: "37045ee6-88d9-4354-8ee9-313c94ab6e69",
      sku: "5632701205",
      sku_detail: "Miller High Life 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.273750000000000",
      manufacturer: "Molson",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "a00456bc-6771-4a1b-b3eb-f7e03c4e0b03",
      experiment_product_id: "390ccd3c-4dac-42ec-8adb-e88d35cd2a1d",
      sku: "323232323",
      sku_detail: "Yes Guy Hard Ice Tea 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "TEA",
      style: "TEA",
      dos_multiplier: "0.730000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "8135558c-5b43-4ebf-9bfa-b92b9dff7aba",
      experiment_product_id: "3eadbf23-2e2c-42dd-bedb-46c56a2d7b89",
      sku: "58782254",
      sku_detail: "NUTRL VODKA SODA 7 MIXER 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.730000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "f2dc22f6-3dc9-4b2c-8a03-1d0750003220",
      experiment_product_id: "416dc9b8-2b16-4dd8-8cd7-28ab46f959dc",
      sku: "77944620034",
      sku_detail: "Granville Island Lions Winter Ale 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.547500000000000",
      manufacturer: "Molson",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "b86f25e0-e4f0-4e89-b47a-c314c91e68d4",
      experiment_product_id: "43ae1474-ceb3-40a1-817b-9de5f916cc38",
      sku: "62699028925",
      sku_detail: "Balderdash Brewing Sneaky Weasel Craft Lager 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.114062500000000",
      manufacturer: "Northam Group",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "0a957253-2d15-40f9-8f27-29c58f18c483",
      experiment_product_id: "44437cd3-eac8-4f3a-85a3-e6a61b6fcbdf",
      sku: "29049637",
      sku_detail: "Fieldhouse Dutch Ale 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.273750000000000",
      manufacturer: "Field House Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "67d47ff2-c52b-42d3-a25e-9b7083971b39",
      experiment_product_id: "46b9e5c3-c4f1-45dc-9b76-ac823a984885",
      sku: "42431248446",
      sku_detail: "Negra Modelo 6/355B",
      width: "7.5",
      height: "9.0",
      depth: "5.0",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.168461538461538",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "7112c59c-fe1f-402b-a3f7-7e351ad92a64",
      experiment_product_id: "4734cc1c-77b8-4c77-92b8-b5ab8d75c8f7",
      sku: "50001041232",
      sku_detail: "STRONGBOW 4/440C",
      width: "5.3",
      height: "6.7",
      depth: "5.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.069523809523810",
      manufacturer: "Strongbow",
      pack_quantity: 4,
      pack_size: 440,
    },
    {
      product_id: "7d72f9eb-06b2-48ee-a962-5e053926e746",
      experiment_product_id: "47c75074-fdc1-48a1-82f6-44648249aae0",
      sku: "695000000000",
      sku_detail: "Bud Light Flavour Mix 12/355C",
      width: "9.3",
      height: "6.3",
      depth: "6.9",
      mapping_count: 0,
      segment: "CORE+",
      style: "CORE+",
      dos_multiplier: "0.730000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "6f494498-7310-4cf6-8c62-e6855ccc67ec",
      experiment_product_id: "4811fda9-6c5e-4690-aa33-94be3eaaab3b",
      sku: "43173036399",
      sku_detail: "Pacific Western Ironhorse 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.062571428571429",
      manufacturer: "Pacific Western Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "7fa6e908-6a0b-44fd-b137-2793b58bee6c",
      experiment_product_id: "48f5da09-e2c6-4f27-afef-3af5d8f240f3",
      sku: "42233195",
      sku_detail: "Mariner Double Berry Sour 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "11.0",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.730000000000000",
      manufacturer: "Mariner Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "ffe56750-b9dc-43dc-9786-114f85c79ac5",
      experiment_product_id: "491011bd-ecc0-4e05-b5f3-33d63384c62f",
      sku: "7745580013",
      sku_detail: "Pacific Western Cariboo Blonde 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.047608695652174",
      manufacturer: "Pacific Western",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "8c3809e2-79a6-48a7-8486-7c62f35728ba",
      experiment_product_id: "4adcceb8-2429-4e3b-9b76-2c0d7001f795",
      sku: "563272732",
      sku_detail: "Molson Extra Old Stock 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.092796610169492",
      manufacturer: "Molson",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "439a10ab-fa27-44ec-b329-73467ca532bf",
      experiment_product_id: "4d6e79c1-00db-4419-90c5-e59ec90131e2",
      sku: "69902358",
      sku_detail: "Red Truck Hop Mixed Pack 8/355C",
      width: "10.4",
      height: "6.2",
      depth: "17.0",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "1.460000000000000",
      manufacturer: "Red Truck Beer Company",
      pack_quantity: 8,
      pack_size: 473,
    },
    {
      product_id: "fb4dac73-2430-4a69-9055-d50b61f1fa1c",
      experiment_product_id: "4d8f730e-171a-477f-b642-9fa260af9cbd",
      sku: "84272466",
      sku_detail: "Premium Pilsner 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.128823529411765",
      manufacturer: "Strathcona Brewery",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "ea25871b-7fe0-42a1-bc10-7cd0904924d2",
      experiment_product_id: "4dea6f49-5e2a-401e-8441-6dd260346fd3",
      sku: "78615000016",
      sku_detail: "Stella Artois 12/330B",
      width: "9.6",
      height: "9.2",
      depth: "7.3",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.208571428571429",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 330,
    },
    {
      product_id: "c88aafd0-5b2b-4c30-b48f-cb8c8c553140",
      experiment_product_id: "51b6722f-dc35-4222-92c7-26a95d3c4836",
      sku: "74008715",
      sku_detail: "Parallel 49 Monster Sour Mixer 8/473C",
      width: "10.4",
      height: "6.2",
      depth: "21.0",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.973333333333333",
      manufacturer: "Parallel 49 Brewing",
      pack_quantity: 8,
      pack_size: 473,
    },
    {
      product_id: "c64ee579-4134-47d9-ae02-1843eca9b0e8",
      experiment_product_id: "52c89c1f-6e7f-47ac-ab49-df92b7c3b11e",
      sku: "78615000001",
      sku_detail: "Stella Artois 6/330B",
      width: "7.5",
      height: "9.0",
      depth: "5.0",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.115263157894737",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 330,
    },
    {
      product_id: "0f5117dd-9173-4772-af47-1648da7a55e9",
      experiment_product_id: "52e9c6f8-f9fc-438b-ba72-524a3e544961",
      sku: "5691060010",
      sku_detail: "Sapporo 6/355B",
      width: "7.5",
      height: "9.0",
      depth: "5.0",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.156428571428571",
      manufacturer: "Sleeman",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "11a69549-5e43-4242-8fe7-941df63e7211",
      experiment_product_id: "554b0f44-053b-44fe-8930-e06a271c75a6",
      sku: "62811300874",
      sku_detail: "Red Racer Pilsner 4/500C",
      width: "5.3",
      height: "6.8",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.069523809523810",
      manufacturer: "Central City Brewing",
      pack_quantity: 4,
      pack_size: 500,
    },
    {
      product_id: "f58f9a84-5bb2-4ba5-bebf-a17c08345b8e",
      experiment_product_id: "55dc0986-1c27-4d0a-ae67-8618cbf007bc",
      sku: "6206735537",
      sku_detail: "Lucky 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.912500000000000",
      manufacturer: "Labatt",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "19a7fde7-6469-4d0f-a977-8cc0fd46bd0a",
      experiment_product_id: "56d9354e-636a-4c80-b12f-778fc1015747",
      sku: "85162100005",
      sku_detail: "Kronenbourg 1664 Blanc 6/330B",
      width: "7.5",
      height: "9.0",
      depth: "5.0",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.136875000000000",
      manufacturer: "Sleeman",
      pack_quantity: 6,
      pack_size: 330,
    },
    {
      product_id: "cccfe342-8e74-4b84-9407-ba14fc3689ce",
      experiment_product_id: "5a2d1d66-64cc-46a4-8f41-08edd8418070",
      sku: "57993739",
      sku_detail: "Bud Light Seltzer Lemonade Strawberry 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SELTZER",
      style: "SELTZER",
      dos_multiplier: "0.146000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "85a19f4e-981f-4177-b184-7a374f4c674a",
      experiment_product_id: "5afd77c4-b1ec-4695-95ef-a4ca5f1fff46",
      sku: "248056253",
      sku_detail: "JAW DROP SQUIRTING ORANGES 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COOLER",
      style: "COOLER",
      dos_multiplier: "0.035322580645161",
      manufacturer: "AQUILINI BRANDS",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "1e3946c6-198c-426d-bc3a-275a20b314d6",
      experiment_product_id: "5c30c8e9-7be6-4a6a-a7ca-203eab9a9158",
      sku: "62067382277",
      sku_detail: "Stella Artois 12/330C",
      width: "9.3",
      height: "6.3",
      depth: "6.9",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.438000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "a623065b-6314-4a92-93e5-894c064c29ae",
      experiment_product_id: "5c9b9f55-b517-4fda-a233-9161d6619173",
      sku: "6206735134",
      sku_detail: "Labatt Blue 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.146000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "6676b70c-03ee-4c1c-af75-d8932ffeb1b4",
      experiment_product_id: "5e76bdf8-2c60-4a46-9876-015c7a4bc469",
      sku: "83933026093",
      sku_detail: "STANLEY PARK SUNSETTER WHEAT 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "065b8b2b-f8ec-4190-93de-486411415326",
      experiment_product_id: "5f2eb506-1670-42ed-884c-5415427ab05b",
      sku: "32651488469",
      sku_detail: "Dust Pale Ale 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.168461538461538",
      manufacturer: "Parkside Brewery",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "173ac6e9-ec2e-4194-b72f-29d6a9067de5",
      experiment_product_id: "5fa28c7b-3b28-4a34-88b6-7e9df9838e02",
      sku: "6206737837",
      sku_detail: "Stanley Park Electro Lager 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "f23685ae-a30b-4ea2-8542-fb3e8b2aae63",
      experiment_product_id: "5fef6add-4c25-49c9-a41e-e77f61fac916",
      sku: "62805558990",
      sku_detail: "Tempo Gin Smash Mix Pack 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "COCKTAIL",
      style: "COCKTAIL",
      dos_multiplier: "0.730000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "f1f1ef6f-c5f9-44f7-ab0c-0f5e40881e36",
      experiment_product_id: "601a5a4b-6967-4482-8669-d50484270427",
      sku: "5691020108",
      sku_detail: "Sleeman Original Draught 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "CORE+",
      style: "CORE+",
      dos_multiplier: "0.153684210526316",
      manufacturer: "Sleeman",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "4e667859-c1b5-4386-977b-16dc8e2b32d3",
      experiment_product_id: "631aa214-4b22-4939-88d9-d3d7200d7845",
      sku: "6206754740",
      sku_detail: "Michelob Ultra 24/355C Sleek",
      width: "13.9",
      height: "9.4",
      depth: "6.4",
      mapping_count: 0,
      segment: "CORE+",
      style: "CORE+",
      dos_multiplier: "1.460000000000000",
      manufacturer: "Labatt",
      pack_quantity: 24,
      pack_size: 355,
    },
    {
      product_id: "e117f346-4e60-43ad-ab05-6ef80825939a",
      experiment_product_id: "63df9378-fd7c-455a-b4bf-31f243fc6e1a",
      sku: "11471013",
      sku_detail: "Phillips Little Wonder Winter Mixer 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "15.0",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "4.380000000000000",
      manufacturer: "Phillips Brewing",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "4f67e6d6-b5fc-463d-8fe4-faaea30fc50d",
      experiment_product_id: "64c20dcd-5380-4a2e-8d75-8d16067fd662",
      sku: "62549570151",
      sku_detail: "Steamworks Craft Lager 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.194666666666667",
      manufacturer: "Steamworks Brewing Company",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "8ce2802a-e54e-4dda-9345-32a151cd184f",
      experiment_product_id: "6794369a-7906-4c8f-868a-d22aa5d086ef",
      sku: "81827800154",
      sku_detail: "Electric Unicorn White IPA 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.115263157894737",
      manufacturer: "Phillips Brewing ",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "8a19369d-23d6-4226-a02a-9aff0faf82e6",
      experiment_product_id: "685266c7-e806-424c-a6f9-08fc01bff5c7",
      sku: "679320173",
      sku_detail: "GROWERS POMEGRANATE 1/2000B",
      width: "4.3",
      height: "12.0",
      depth: "4.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.016590909090909",
      manufacturer: "Constellation Brands",
      pack_quantity: 1,
      pack_size: 2000,
    },
    {
      product_id: "d0164623-ceb4-4473-aae7-346335646fdf",
      experiment_product_id: "68f36645-3be2-474d-b859-2971a15816ea",
      sku: "84830374",
      sku_detail: "Busch 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.912500000000000",
      manufacturer: "Labatt",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "ef099430-cc06-4725-bf09-fbc278a839de",
      experiment_product_id: "6a167992-9800-4116-8065-008654246d9e",
      sku: "62699017976",
      sku_detail: "Parallel 49 Tricycle Radler 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Parallel 49 Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "29efb669-bb8c-4003-a769-ab8d60efaf4b",
      experiment_product_id: "6abcf6ae-808e-4866-baa7-ee316dc7ab9c",
      sku: "74153515",
      sku_detail: "NUDE VODKA SODA MIXER 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.438000000000000",
      manufacturer: "Orchard City",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "d3202542-2312-4e69-ad32-dfb264e8c90a",
      experiment_product_id: "6b094982-7da6-4bc1-b1d8-a8370fcdf321",
      sku: "5691040137",
      sku_detail: "Old Milwaukee 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.043110236220472",
      manufacturer: "Sleeman",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "4a20821c-259f-4979-9d07-728e86b04687",
      experiment_product_id: "6b8a91a1-ddac-4d69-8952-08bc496a021d",
      sku: "66497220",
      sku_detail: "Side Project Tequila Cactus Fruit 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SELTZER",
      style: "SELTZER",
      dos_multiplier: "0.109500000000000",
      manufacturer: "Side Project",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "d4d4321b-7529-4153-bf3a-3d8a6696467f",
      experiment_product_id: "6ead5ac9-cbb6-49bb-82e5-91e2da2eb962",
      sku: "35945812",
      sku_detail: "Moody Ales Lavender Sour 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.121666666666667",
      manufacturer: "Moody Ales LTD",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "9c0d1ee4-8131-4fe6-ae37-f4b1891f08f5",
      experiment_product_id: "70d91b69-2669-4bb3-8e13-3103a1e0877a",
      sku: "6206737999",
      sku_detail: "Stanley Park Waypoint Hazy Pale Ale 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "726914eb-1f68-460c-b6c9-c70af21fda01",
      experiment_product_id: "71211f5a-ffee-4e0a-8a43-338b4cc22d54",
      sku: "84340300048",
      sku_detail: "Nutrl Juicd Punch 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "4b2bafec-a377-4da7-828d-7babf5abb469",
      experiment_product_id: "740439fa-7236-4a87-b425-4f313ed9620a",
      sku: "107196373",
      sku_detail: "Side Project Jalepeno Lime 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.146000000000000",
      manufacturer: "Side Project",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "bab95b35-c79c-4928-b153-e84a35eea8c4",
      experiment_product_id: "74f10f0a-d7e8-4887-84e6-dd6a5486d2d6",
      sku: "8200077389",
      sku_detail: "SMRNFF ICE 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COOLER",
      style: "COOLER",
      dos_multiplier: "0.136875000000000",
      manufacturer: "Diageo",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "b80cebb8-ec09-4ce0-a9a9-ac0488aa1f45",
      experiment_product_id: "76827d7a-0404-4d11-bd88-39426df2cec8",
      sku: "8280555892",
      sku_detail: "NUTRL VODKA SODA PINEAPPLE 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.043800000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "63915458-a565-44a2-a84c-c336be8d9819",
      experiment_product_id: "791f5dd3-9ad8-4263-9363-d8308e9ab492",
      sku: "120673811",
      sku_detail: "Okanagan Premium Saskatoon Berry 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "2d4923cb-d22c-49d7-bd76-84845e183bad",
      experiment_product_id: "7c1060b8-798d-4d1c-9b86-d6e9effb7fa4",
      sku: "5632701238",
      sku_detail: "Miller Genuine Draft 6/355B",
      width: "7.5",
      height: "9.0",
      depth: "5.0",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "0.099545454545455",
      manufacturer: "Molson",
      pack_quantity: 6,
      pack_size: 335,
    },
    {
      product_id: "6044f905-23ec-40de-b759-c37bc6ca8727",
      experiment_product_id: "7c3b6945-7669-4fe4-adb7-760d2a8e742b",
      sku: "89938587",
      sku_detail: "Lonetree Cider Rhubarb 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.109500000000000",
      manufacturer: "Northam Brewery",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "71dd6a75-9cde-4adc-893a-31f117af0f6d",
      experiment_product_id: "7c8b8d06-1860-4505-bdf9-bafcb7d5bc09",
      sku: "1859020017",
      sku_detail: "Mash Up 8/473C",
      width: "10.4",
      height: "6.2",
      depth: "5.2",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.162222222222222",
      manufacturer: "Steamworks Brewing Company",
      pack_quantity: 8,
      pack_size: 473,
    },
    {
      product_id: "0a505044-725b-4024-a3cf-ae297e9e878c",
      experiment_product_id: "82e78d23-9283-4bfe-8d42-aac1f307470a",
      sku: "56087261",
      sku_detail: "Other Wildeye 4/0.473 Can",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.146000000000000",
      manufacturer: "Wildeye brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "9bbdd0ad-d161-4a76-b378-fddc56b79f8c",
      experiment_product_id: "842dd0b4-0c00-48cf-b0dd-348ec8f7487b",
      sku: "6206737437",
      sku_detail: "Kokanee 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "0.121666666666667",
      manufacturer: "Labatt",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "c5cf8020-030a-4e39-b3b2-5b077a5d2487",
      experiment_product_id: "84d24aca-d62b-4c5d-9c94-e9f59ed70b2e",
      sku: "5691040323",
      sku_detail: "Pabst Blue Ribbon 24/355C",
      width: "15.9",
      height: "10.6",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.547500000000000",
      manufacturer: "Sleeman",
      pack_quantity: 24,
      pack_size: 355,
    },
    {
      product_id: "5ef5bf70-9996-433c-af6d-051d8644fc3f",
      experiment_product_id: "85d12387-2dac-47d7-a3ae-a4c13f817c3c",
      sku: "620671824",
      sku_detail: "Okanagan Premium Crisp Apple 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "a36dc74b-4d2b-47e3-8b99-af682b2c2628",
      experiment_product_id: "87776b77-bbaf-456e-b462-d614d60efa5e",
      sku: "62845120705",
      sku_detail: "Guardian IPA 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.146000000000000",
      manufacturer: "Strange Fellows Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "f106a3f6-7138-4bec-ba73-9dbc00d92c19",
      experiment_product_id: "88517455-0984-46dc-bd05-5d02ae067d01",
      sku: "62067516245",
      sku_detail: "Bud Light Lime 12/355C",
      width: "9.3",
      height: "6.3",
      depth: "6.9",
      mapping_count: 0,
      segment: "CORE+",
      style: "CORE+",
      dos_multiplier: "0.730000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "c3ab07f9-a27f-458a-a9d8-b2b67fc0529e",
      experiment_product_id: "8ac5432d-2353-40b2-bc69-69c9771c4c26",
      sku: "97379484",
      sku_detail: "Corona Tropical Grapefruit Lemongrass 6 Can",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SELTZER",
      style: "SELTZER",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "7769f920-ee30-4cac-8427-262968ffe0c1",
      experiment_product_id: "8b46256d-0309-46bf-9c8f-bd2fc5e2ff15",
      sku: "77455800053",
      sku_detail: "Pacific Western Cariboo Genuine Draft 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.045154639175258",
      manufacturer: "Pacific Western",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "874d7b6d-df3d-4fea-8408-b17be2af602b",
      experiment_product_id: "8c597c1d-a716-47fa-8f51-a3ab30c4cd82",
      sku: "62699017035",
      sku_detail: "Bowen Island Harbour Pack #2 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.486666666666667",
      manufacturer: "Northam Brewery",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "8b4cb574-6003-441c-8456-89937baa8cee",
      experiment_product_id: "8f752a4e-6f14-42e9-85f5-5a6c8758e32c",
      sku: "13222768175",
      sku_detail: "Miller Lite 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "0.456250000000000",
      manufacturer: "Molson",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "6ef8ef61-3a47-402c-91fb-c005930ae6ef",
      experiment_product_id: "8fb71e9b-3116-4cda-a7d4-4e89068c5cfa",
      sku: "6206738752",
      sku_detail: "Stanley Park Yuzu Sour 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.243333333333333",
      manufacturer: "Labatt",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "4c0cd70b-9640-4b46-b5dc-407e3bca5f0c",
      experiment_product_id: "8fd9e0a2-31c5-4d0b-bbd8-52c4eadcd674",
      sku: "6280555894",
      sku_detail: "Nutrl 7 Black Cherry 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "cd924b16-c614-4b09-954f-064a93f6f20a",
      experiment_product_id: "9011accf-e76f-404a-9d48-3d088a7df28f",
      sku: "30496503",
      sku_detail: "Other Boombox 4/0.473 Can",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.132727272727273",
      manufacturer: "Parallel 49 Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "e6d253c8-a33a-4cc8-97b2-c8047def4c2f",
      experiment_product_id: "9114e220-f001-40d0-a713-a648a7461cee",
      sku: "620674143",
      sku_detail: "MIKES HARD LEMONADE 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COOLER",
      style: "COOLER",
      dos_multiplier: "0.136875000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "0c252613-aaf0-4df8-9307-bec9a75fd554",
      experiment_product_id: "919eeb98-6236-4c20-9290-86af4948a10c",
      sku: "62699005123",
      sku_detail: "Bowen Island West Coast Lager 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.168461538461538",
      manufacturer: "Northam Brewery",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "86d41150-cb12-491a-b759-b4a2efba2c8b",
      experiment_product_id: "92163f49-d23a-41a3-bf41-89943d31960f",
      sku: "444214152",
      sku_detail: "Molson Extra Old Stock 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.070645161290323",
      manufacturer: "Molson",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "e1df0037-edb4-4aab-aeb4-3bda26526473",
      experiment_product_id: "93bf6345-8b30-4ac9-a20a-533c43ab2aa1",
      sku: "27861584387",
      sku_detail: "Other Shaftebury 6/0.355 Can",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.078214285714286",
      manufacturer: "Sleeman",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "1edbd1e5-a3e5-43ed-b214-86db58dde285",
      experiment_product_id: "94d22721-5a61-469d-8c3c-8bac6b137fbc",
      sku: "636570399",
      sku_detail: "GROWERS CIDER CLMNTN PINEAPPL 1/2000B",
      width: "4.3",
      height: "12.0",
      depth: "4.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.028076923076923",
      manufacturer: "Constellation Brands",
      pack_quantity: 1,
      pack_size: 2000,
    },
    {
      product_id: "12cefe91-8eea-4369-818a-b44d8f4ef6e3",
      experiment_product_id: "95d6bed2-89ec-4e9c-ae94-885a24c89909",
      sku: "73586251325",
      sku_detail: "Stanley Park Park Shadow Play 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "d7f40d7d-b84f-4a2c-a539-97700dcaec04",
      experiment_product_id: "96d59c24-326b-4646-a402-91c9e39ef417",
      sku: "5632707327",
      sku_detail: "Molson Canadian 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "0.260714285714286",
      manufacturer: "Molson",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "4cd63a5a-392f-423b-aa10-20505868d5c3",
      experiment_product_id: "99a80e34-ae9c-4b7a-8d35-07607a472e4c",
      sku: "8580540023",
      sku_detail: "Red Truck Ale 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.486666666666667",
      manufacturer: "Red Truck Brewing",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "813fd820-a13d-46fb-8ffa-a9d02f069165",
      experiment_product_id: "9b0d2e3f-4659-4618-8bdf-5af2c6bdbe8d",
      sku: "78111228611",
      sku_detail: "Nutrl Juicd Watermelon 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.104285714285714",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "f732644f-286a-42af-ac42-d4312fc22db3",
      experiment_product_id: "9b3def87-ce3f-4eb3-a29c-9fbf5e9dc0ab",
      sku: "85590000166",
      sku_detail: "Deep Cove West Coast Pale Ale 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Deep Cove Brewers",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "31e333e9-37f0-4236-8ba3-816308afb5ff",
      experiment_product_id: "9d1e0255-00e3-4353-ac29-d801aa07d2ab",
      sku: "75816435",
      sku_detail: "NUTRL VODKA CHERRY 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "ce60e72f-b461-4051-a829-d85c40790d7e",
      experiment_product_id: "9dce6d15-ba2b-4826-b667-b65e567bef90",
      sku: "6206735545",
      sku_detail: "Lucky Lager 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.153684210526316",
      manufacturer: "Labatt",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "ed6e7625-2a68-4532-b19d-0b8cc689a20e",
      experiment_product_id: "9ef0877b-22a1-43dc-96ed-1efcd38883bb",
      sku: "62805558964",
      sku_detail: "Tempo Gin Lemon Mint 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COCKTAIL",
      style: "COCKTAIL",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "a3d0f51f-2b9f-4d19-aebf-9c847246628b",
      experiment_product_id: "9f4be62e-fca9-4356-98dc-ae72c3727622",
      sku: "74613010",
      sku_detail: "HEY YALL MAI TAI ICED TEA 6/341C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "TEA",
      style: "TEA",
      dos_multiplier: "0.128823529411765",
      manufacturer: "Northam Brewery",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "c08aff05-d0bb-4bc5-ab38-500de2c960ef",
      experiment_product_id: "9f7a4c4a-a667-4c8c-afaf-bcd45023c27b",
      sku: "62067335402",
      sku_detail: "Budweiser 24/355C",
      width: "15.9",
      height: "10.6",
      depth: "5.0",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "1.251428571428571",
      manufacturer: "Labatt",
      pack_quantity: 24,
      pack_size: 355,
    },
    {
      product_id: "2f69253a-14c4-4a68-9719-d51356b055dd",
      experiment_product_id: "a2e8a814-f123-4d94-9b91-b39769a9cc89",
      sku: "50923315",
      sku_detail: "WIZE SWEATER VODKA SODA MIXER 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.208571428571429",
      manufacturer: "PROVINCIAL SPIRITS LTD.",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "bfa820e1-a307-43a3-9279-b8e2e353b755",
      experiment_product_id: "a3c8f0e5-c71a-41e1-a325-de0d8b32c181",
      sku: "97865888",
      sku_detail: "33 Acres of Ocean Pale Ale 6/330B",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.075517241379310",
      manufacturer: "33 Acres Brewing Co",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "a62bfe12-345f-47fb-aece-caf0eba97e10",
      experiment_product_id: "a3f43563-a1a5-4b98-8dfe-43b8bbe6c15d",
      sku: "679320204",
      sku_detail: "GROWERS PEAR CIDER 1/2000B",
      width: "4.3",
      height: "12.0",
      depth: "4.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.012166666666667",
      manufacturer: "Constellation Brands",
      pack_quantity: 1,
      pack_size: 2000,
    },
    {
      product_id: "64c8f012-8c48-4f1e-97cd-8adad828a2d0",
      experiment_product_id: "a546a42e-1edb-4ef3-b571-f2bf20dac00d",
      sku: "6206737440",
      sku_detail: "Kokanee 24/355C",
      width: "15.9",
      height: "10.6",
      depth: "5.0",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "0.876000000000000",
      manufacturer: "Labatt",
      pack_quantity: 24,
      pack_size: 355,
    },
    {
      product_id: "c5e92694-34fe-4b28-bde7-63d175d6b8a2",
      experiment_product_id: "a5760f9d-bca4-4d7f-a493-fa591fa4e0e1",
      sku: "21216523",
      sku_detail: "Bud Light Seltzer Lemonade Mixer 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "SELTZER",
      style: "SELTZER",
      dos_multiplier: "0.438000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "5bd960a2-2b7b-4b83-9b80-3a3d6fcc262d",
      experiment_product_id: "aa3fd9b3-c666-436e-ac3f-b2d1e1bbac88",
      sku: "6206738399",
      sku_detail: "Bud Light Mango 12/355C",
      width: "9.3",
      height: "6.3",
      depth: "6.9",
      mapping_count: 0,
      segment: "CORE+",
      style: "CORE+",
      dos_multiplier: "0.730000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "fd47dde3-3700-410a-b04f-df1070932eb3",
      experiment_product_id: "acbb7ce8-ba0c-4e3b-9273-a78c1497f71f",
      sku: "472000000000",
      sku_detail: "PALMBAY ESCAPE THE SUN VARIETY PACK 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "COOLER",
      style: "COOLER",
      dos_multiplier: "0.438000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "8eae23de-cd7d-45b5-9746-74653a6fe254",
      experiment_product_id: "af4228ba-7f3d-4b1e-97ff-29e8aa62584f",
      sku: "6206737774",
      sku_detail: "Michelob Ultra 6/355C",
      width: "7.0",
      height: "6.3",
      depth: "4.6",
      mapping_count: 0,
      segment: "CORE+",
      style: "CORE+",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "c5e13b04-03bc-43b7-b22c-a78aad6fb21f",
      experiment_product_id: "b02f0d1f-1fd4-4cbb-a4d6-690404a682bd",
      sku: "6206738754",
      sku_detail: "Stanley Park Tangerine Tea Hazy IPA 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.243333333333333",
      manufacturer: "Labatt",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "391238dd-e34a-4ad6-be23-f4137412e351",
      experiment_product_id: "b154bbe8-34f4-4de8-be55-8794dd04cef6",
      sku: "500000000000",
      sku_detail: "Kilkenny Irish Cream Ale 4/500C",
      width: "5.3",
      height: "6.8",
      depth: "5.3",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.112307692307692",
      manufacturer: "Diageo",
      pack_quantity: 4,
      pack_size: 500,
    },
    {
      product_id: "f251361c-062c-40ad-ba05-cf91ad932f7e",
      experiment_product_id: "b3751d7a-01c5-4ffd-a00f-97dc9bc86874",
      sku: "64106155664",
      sku_detail: "Bridge Brewing Bourbon Blood Orange Wheat 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.132727272727273",
      manufacturer: "Bridge Brewing ",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "a73cab99-8f95-4bd7-a27e-de412fb6dc7e",
      experiment_product_id: "b513d4c0-32d7-42ac-bf01-05ea65ee9f23",
      sku: "11300843",
      sku_detail: "Strathcona Lager 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.073000000000000",
      manufacturer: "Strathcona Brewery",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "9fbe2684-9117-43e2-83a9-6e0f87b97882",
      experiment_product_id: "b64cee46-7e00-4d2e-a251-987d49daf719",
      sku: "6280555896",
      sku_detail: "Other Nutrl 7 6/0.355 Can",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.048666666666667",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "cb29d9d6-c660-4994-9e83-5cc5380cd75c",
      experiment_product_id: "b6f61c46-d39c-4edf-a063-1dad6b514920",
      sku: "569104040",
      sku_detail: "Rainer 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.153684210526316",
      manufacturer: "Sleeman",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "1bdd0bb1-be2b-4289-84ea-e98caa569d25",
      experiment_product_id: "b89d0f02-5753-49f0-9f2c-b5b052464810",
      sku: "82399400078",
      sku_detail: "Parallel 49 Trash Panda 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Parallel 49 Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "fed590bf-aa06-46d1-973a-e407dcc872ad",
      experiment_product_id: "ba38e96c-cbcc-4567-b8ee-8edd4ba7c66d",
      sku: "62699026593",
      sku_detail: "Balderdash Brewing Sneaky Weasel Craft Lager 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.047608695652174",
      manufacturer: "Northam Group",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "9fbb4865-e81a-4322-9611-2c3609553f7f",
      experiment_product_id: "ba480650-cec4-4939-b46d-630d28f0e10e",
      sku: "22351493732",
      sku_detail: "Wildcat Strong 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.912500000000000",
      manufacturer: "Labatt",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "338f0b47-ebd0-47e1-aeb4-24810fe3568e",
      experiment_product_id: "bbcf9424-90e2-4e1c-a539-ccad080a92b3",
      sku: "871000000000",
      sku_detail: "Bavaria Original 6/500C",
      width: "5.2",
      height: "6.6",
      depth: "5.2",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.060833333333333",
      manufacturer: "Groupe Omni",
      pack_quantity: 6,
      pack_size: 500,
    },
    {
      product_id: "d11277dc-64eb-48af-96dc-42afe9174651",
      experiment_product_id: "bcd9fa10-3e4a-4f1f-a436-ac2810caff6b",
      sku: "5632711323",
      sku_detail: "Molson Black Label Supreme 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.024606741573034",
      manufacturer: "Molson",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "8bcc67f8-c1ba-43bc-bb62-c782e5b93211",
      experiment_product_id: "bd121889-677d-40e8-942d-d7edff2ba0d2",
      sku: "6288820000",
      sku_detail: "Red Truck Adventure Pack 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.486666666666667",
      manufacturer: "Red Truck Brewing",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "3d64e0c5-f45e-4dde-8d9b-3cbd26894429",
      experiment_product_id: "bda5ee12-d052-40c6-b7d4-0019d4ebadd5",
      sku: "62809998988",
      sku_detail: "Tempo Gin Strawberry Lemon 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COCKTAIL",
      style: "COCKTAIL",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "c339bb8b-55bc-461f-8163-8a09cd8abb0a",
      experiment_product_id: "bda8a2d1-6f62-4d20-9fde-3fc247b0b9f6",
      sku: "6206738370",
      sku_detail: "NUTRL VODKA SODA MIX PACK 12/355ML",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.044693877551020",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "075477b6-f8e8-4784-b732-260039aac980",
      experiment_product_id: "bf3b3e98-9a4d-4ad9-9a79-281928d2b226",
      sku: "62820111112",
      sku_detail: "Driftwood Brewery Fat Tug IPA 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.087600000000000",
      manufacturer: "Driftwood Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "4731760f-2c84-471e-b114-6a6dad53d86f",
      experiment_product_id: "bf51f581-1c82-4e62-a4f3-1fc5eaccb61c",
      sku: "5691020115",
      sku_detail: "Sleeman Original Draught 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "CORE+",
      style: "CORE+",
      dos_multiplier: "0.421153846153846",
      manufacturer: "Sleeman",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      product_id: "c5157c4a-9b76-4cec-8a49-45672a9dc3a0",
      experiment_product_id: "bf8215f7-fec7-4bae-bb80-e22636e7c078",
      sku: "62805558916",
      sku_detail: "NUTRL VODKA SODA LEMON 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "a7ac15c2-052d-4089-b181-29f532ec20be",
      experiment_product_id: "c00cdae1-42d7-4770-a043-96aee6373d17",
      sku: "6206733534",
      sku_detail: "Budweiser 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "0.064411764705882",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "7cd4c5dd-b646-4a44-8358-35fe56f719c2",
      experiment_product_id: "c16349ec-73b2-4c54-bf3d-cf890e550f45",
      sku: "6206737574",
      sku_detail: "Stanley Park Trailhopper 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "6b66e2e4-b59b-46a6-9fc1-89711f1ded71",
      experiment_product_id: "c1fe14bf-22a8-4007-9c58-c4e4f31e94cd",
      sku: "40696313",
      sku_detail: "Corona Tropical Lime Cactus 6 Can",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SELTZER",
      style: "SELTZER",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "d134a30a-30fa-4fe2-83a6-8e2208e57340",
      experiment_product_id: "c429316a-8309-4409-afe0-97c5a4b2ee8d",
      sku: "6269902505",
      sku_detail: "HEY YALL ORIGINAL HARD ICED TEA 12/341C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "TEA",
      style: "TEA",
      dos_multiplier: "0.099545454545455",
      manufacturer: "Northam Group",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "daed12fd-c956-4361-bc7f-bfe0fe3e576d",
      experiment_product_id: "c7258f7f-9a26-4ef5-9d32-9e4607f788d4",
      sku: "31897506",
      sku_detail: "Palm Bay PINEAPPLE MANDARIN ORANGE 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COOLER",
      style: "COOLER",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "de23014e-e1a6-43fa-8baf-0e07c54babe8",
      experiment_product_id: "c7355cbf-9f1e-460b-8d18-4ed26753afc8",
      sku: "7280555898",
      sku_detail: "Tempo Gin Grapefruit 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COCKTAIL",
      style: "COCKTAIL",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "278a00c8-337c-4b76-a382-e1aa42db2e77",
      experiment_product_id: "c7e5ddcc-db46-4952-8207-8dd7b3a97c08",
      sku: "78951733",
      sku_detail: "Cerveceria Salt Lime 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.076842105263158",
      manufacturer: "Cerveceria Astilleros",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "2ac4d704-2bba-4516-9428-670df6067a83",
      experiment_product_id: "cae29343-553a-4c5a-86d8-62b6782a44f8",
      sku: "59761629255",
      sku_detail: "GROWERS BLACKBERRY 1/2000B",
      width: "4.3",
      height: "12.0",
      depth: "4.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.030416666666667",
      manufacturer: "Constellation Brands",
      pack_quantity: 1,
      pack_size: 2000,
    },
    {
      product_id: "16b61e03-c6f7-48e2-bd9a-84d5c0a8191d",
      experiment_product_id: "ce8a2e22-e34e-4adc-a871-86b7e1280419",
      sku: "90912570535",
      sku_detail: "Stanley Park Fruit Stand Mixer 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.730000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "049d540c-c971-4f94-83c3-471fc29b30cc",
      experiment_product_id: "cf112f58-af98-4b31-8ad3-478060a88e35",
      sku: "105940560",
      sku_detail: "Virtue Lemonade 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COOLER",
      style: "COOLER",
      dos_multiplier: "0.146000000000000",
      manufacturer: "Virtue Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "1241316f-53df-4652-8380-5a915f67242f",
      experiment_product_id: "cfd5ed2e-0d66-4674-a376-1e9452fd0b33",
      sku: "62845120701",
      sku_detail: "Strange Fellows Talisman Pale Ale 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.042941176470588",
      manufacturer: "Strange Fellows Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "4213f1ee-6f6e-4980-837d-313ae9b7df2f",
      experiment_product_id: "d00e01ea-dd1f-4ff6-8833-de641cb63919",
      sku: "679320003",
      sku_detail: "GROWERS EXTRA DRY APPLE 1/2000B",
      width: "4.3",
      height: "12.0",
      depth: "4.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.021470588235294",
      manufacturer: "Constellation Brands",
      pack_quantity: 1,
      pack_size: 2000,
    },
    {
      product_id: "e5033ab3-6203-4be1-8906-6d7a686a4ed0",
      experiment_product_id: "d0e7a804-a4c5-4a59-aca2-289d64f5344e",
      sku: "620671233",
      sku_detail: "Kootney True Ale 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.087600000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "8b8ff8b0-bea7-4bcb-bfd3-7db943f8a5ee",
      experiment_product_id: "d2de6114-0600-425a-9734-37b74939c027",
      sku: "62888200089",
      sku_detail: "Remix Vodka Soda Vol 2 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.100689655172414",
      manufacturer: "Remix",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "deee424c-1bbf-496a-a1ff-9e34c49c45e9",
      experiment_product_id: "d336a1c5-10ea-43b9-81d7-ba6ca414f309",
      sku: "7289000003",
      sku_detail: "Heineken 6/330B",
      width: "7.5",
      height: "9.0",
      depth: "5.0",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.136875000000000",
      manufacturer: "Molson",
      pack_quantity: 6,
      pack_size: 330,
    },
    {
      product_id: "80a3d587-931d-4595-90bc-221b08c9e01b",
      experiment_product_id: "d4120b00-072b-4886-8a15-982d31ee85a3",
      sku: "10202486",
      sku_detail: "Bud Light Seltzer Out Of Office Mixer 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 0,
      segment: "SELTZER",
      style: "SELTZER",
      dos_multiplier: "0.438000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      product_id: "43619dfe-6d28-49f5-8804-8152de481025",
      experiment_product_id: "d44b5122-4219-47a8-b92e-d47f4a256549",
      sku: "16591292",
      sku_detail: "Patina Kiwi Sour 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.182500000000000",
      manufacturer: "Patina Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "d6252298-1fb0-4c65-9f55-68d148dafd69",
      experiment_product_id: "d55f4685-21c3-449e-8628-61fa5c9294dc",
      sku: "7280555896",
      sku_detail: "Nutrl 7 Blackberry 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "c9806078-5d77-4afb-8feb-3c57f8a1db79",
      experiment_product_id: "d57e3f73-ab99-44df-a416-e1fd488ebcf2",
      sku: "93956405",
      sku_detail: "33 Acres Mezcal Lime Gose 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.048666666666667",
      manufacturer: "33 Acres Brewing Co",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "eb5fa91c-af7e-4ee1-b053-b53c57fcf726",
      experiment_product_id: "d6163273-2e80-4e3f-83f5-f6df495bc057",
      sku: "84608119",
      sku_detail: "NUTRL JUICD MIXER 8/355C",
      width: "7.9",
      height: "4.9",
      depth: "7.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "fbb32559-f494-489f-8c4f-08c79e8e7e9b",
      experiment_product_id: "d9e4b704-4be8-49f4-8bd5-e68d1925e959",
      sku: "8269903670",
      sku_detail: "PYUR VODKA SODA LIME 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.049772727272727",
      manufacturer: "Northam Group",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "052f3a52-0c8e-458b-9120-43cad8e9ae07",
      experiment_product_id: "ddb1d2a5-2bab-438f-90ce-1518b3edeaee",
      sku: "6206756734",
      sku_detail: "Bud Light 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "0.199090909090909",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "5a28a240-2f03-41e6-9d46-117b425843cb",
      experiment_product_id: "dddb6949-9eab-4972-8123-f1cedbe3d3bd",
      sku: "62784375011",
      sku_detail: "Backcountry Brewing Widowmaker IPA 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.162222222222222",
      manufacturer: "Backcountry Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "ae3dc084-84c8-4767-bc84-182a5f809022",
      experiment_product_id: "dea2b476-b733-4fc5-92c4-3459f0cd5016",
      sku: "85050700143",
      sku_detail: "Four Winds La Maison Wild Saison 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Four Winds Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "f0df71f1-5d75-43ca-b4bf-e279e5509708",
      experiment_product_id: "dee8b234-8449-4f86-a9d8-678d3d3dee15",
      sku: "83446629",
      sku_detail: "Beldame Pilsner 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.121666666666667",
      manufacturer: "Strange Fellows Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "15a09d0a-fc8e-473f-8155-cfb645bc80dd",
      experiment_product_id: "e16d54ec-d1dd-4a4c-9cbe-79ce1e5a3705",
      sku: "17930013",
      sku_detail: "Strait & Narrow Explorer Pack 6/355C",
      width: "7.0",
      height: "6.3",
      depth: "4.6",
      mapping_count: 0,
      segment: "COCKTAIL",
      style: "COCKTAIL",
      dos_multiplier: "0.052142857142857",
      manufacturer: "Victoria Distillers",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "ed2d5780-d019-4c2a-80e1-6ea7f1c1dc01",
      experiment_product_id: "e3531be0-398f-4db3-a877-923d25b56861",
      sku: "62811300628",
      sku_detail: "Red Racer IPA 4/500C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.208571428571429",
      manufacturer: "Central City Brewing",
      pack_quantity: 4,
      pack_size: 500,
    },
    {
      product_id: "7c10d595-5282-4224-8838-fcb5e697a5d9",
      experiment_product_id: "e3d03e9c-5277-48d0-b3b4-a8e3f74f608a",
      sku: "5691040140",
      sku_detail: "Old Milwaukee 24/355C",
      width: "15.9",
      height: "10.6",
      depth: "5.0",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.417142857142857",
      manufacturer: "Sleeman",
      pack_quantity: 24,
      pack_size: 355,
    },
    {
      product_id: "9ca773fb-e36b-4fb9-91c7-b978cfac0325",
      experiment_product_id: "e4665d80-6e6e-415d-8464-7b8c344b3fd4",
      sku: "123123264",
      sku_detail: "WHITE CLAW NATURAL LIME 6/355C",
      width: "7.0",
      height: "6.3",
      depth: "4.6",
      mapping_count: 0,
      segment: "SELTZER",
      style: "SELTZER",
      dos_multiplier: "0.056153846153846",
      manufacturer: "Mark Anthony",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "05b8060d-4d2e-49bf-a9d9-4de8498459d8",
      experiment_product_id: "e5493cf2-6892-4b9c-845d-af7c47b766ef",
      sku: "100027133",
      sku_detail: "COCO FRIO LIME 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SELTZER",
      style: "SELTZER",
      dos_multiplier: "0.066363636363636",
      manufacturer: "Northam Brewery",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "e824b020-ae8d-41f5-962d-727c7d793674",
      experiment_product_id: "e57424f6-bae4-4ea0-9d25-7edeafd769c5",
      sku: "50135990",
      sku_detail: "BUMPER CROP CRISP APPLE 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.039107142857143",
      manufacturer: "Northam Group",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "c8dd93a8-09ee-46bc-9310-8215aa002e51",
      experiment_product_id: "e82da440-609e-4cae-bf15-3694ce34d6ba",
      sku: "7887383202",
      sku_detail: "P49 Craft Lager 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.078214285714286",
      manufacturer: "Parallel 49 Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "04b82661-dcd1-45b5-93c9-ff16838abd70",
      experiment_product_id: "e912caa3-3dfd-4a56-951e-304d32a240ee",
      sku: "1501771",
      sku_detail: "Howe Sound Lager 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.146000000000000",
      manufacturer: "Howe Sound ",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "564632df-5a7a-4c33-bf50-14c135073d75",
      experiment_product_id: "ec6ad3ac-1d03-4bfd-a578-d721aec391f5",
      sku: "97399259487",
      sku_detail: "OKANAGAN HARVEST BLACK CHRY6PK 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "9502c73e-e42f-4ea7-b4bb-fa3ac8fa1b1f",
      experiment_product_id: "eca582a2-78fc-46a3-a333-4d93cdc93a95",
      sku: "234000000000",
      sku_detail: "Parallel 49 Filthy Dirty 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Parallel 49 Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "567a1c1f-0cd0-451f-8c41-56971bc38b81",
      experiment_product_id: "ed83fdcc-3485-4f6c-b70e-450c5f77542d",
      sku: "6591535901",
      sku_detail: "Bridge Brewing Primetime 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.156428571428571",
      manufacturer: "Bridge Brewing ",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "4c4d870c-181b-4f86-a26a-f921a3ca2a89",
      experiment_product_id: "ef0a28c4-34c7-4eaf-ab8c-208c254ca85c",
      sku: "41028416",
      sku_detail: "Strathcona Blue Candy 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.121666666666667",
      manufacturer: "Strathcona Brewery",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "c9e3189e-af5d-411f-bd00-f7b709104040",
      experiment_product_id: "ef20becb-7734-46b5-af37-27082c8e65ac",
      sku: "6206714243",
      sku_detail: "PALM BAY GRAPEFRUIT 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COOLER",
      style: "COOLER",
      dos_multiplier: "0.168461538461538",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "f25e4479-9d95-4df4-bb77-cdda3b85a400",
      experiment_product_id: "f0b40c88-fc3f-4364-baa5-4f919a3430d6",
      sku: "84608118",
      sku_detail: "NUTRL MOSCOW MULE 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "COCKTAIL",
      style: "COCKTAIL",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "e2a29108-0a9d-48ad-968c-82a98c96f66d",
      experiment_product_id: "f2047ed5-c0d9-4cbc-84e8-2529d8b72d11",
      sku: "5691040130",
      sku_detail: "Old Milwaukee 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.047608695652174",
      manufacturer: "Sleeman",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "a1070057-3a88-4d0e-a8b4-d1832ceb474c",
      experiment_product_id: "f3819e26-37b5-4dc4-983d-1c59fe515937",
      sku: "62699030685",
      sku_detail: "Other Yellow Dog Brewing 4/0.473 Can",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.292000000000000",
      manufacturer: "Yellow Dog Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "5cee9692-6cd5-4a59-8015-5ecf8f4c4277",
      experiment_product_id: "f3cff121-83d5-4bc0-8c20-e4d5aa7a9dc2",
      sku: "62699035990",
      sku_detail: "Brewhall Kol Story Bro Kolsch 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.182500000000000",
      manufacturer: "Brewhall Beer",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      product_id: "4f77b4c1-6b1c-45b4-a97a-a24c9f976a94",
      experiment_product_id: "f8466013-5596-4f13-8d8f-fd2e991edf33",
      sku: "85050700141",
      sku_detail: "Four Winds Featherweight IPA 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.438000000000000",
      manufacturer: "Four Winds Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "42daf6f5-94f8-4ce3-8785-7d5f20240ad6",
      experiment_product_id: "f8ed9ae9-78a3-4f3b-ae12-b0509d8aa896",
      sku: "35256498",
      sku_detail: "Coors Original 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "0.162222222222222",
      manufacturer: "Molson",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      product_id: "0f42e70c-6419-437c-9863-3b2fd0480009",
      experiment_product_id: "f9ada234-8ae4-4978-9e45-b39bc0a1724f",
      sku: "91779702",
      sku_detail: "WIZE VODKA SODA ORANGESICLE 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.099545454545455",
      manufacturer: "PROVINCIAL SPIRITS LTD.",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "4c880a66-2960-4a4c-baad-3fdb888da02a",
      experiment_product_id: "fa6f7962-7540-492a-a82c-4a21ca568100",
      sku: "7289000085",
      sku_detail: "Heineken 12/330B",
      width: "9.6",
      height: "9.2",
      depth: "7.3",
      mapping_count: 0,
      segment: "IMPORT",
      style: "IMPORT",
      dos_multiplier: "0.625714285714286",
      manufacturer: "Molson",
      pack_quantity: 12,
      pack_size: 330,
    },
    {
      product_id: "180755e1-e29e-468e-8911-f89f14cd445e",
      experiment_product_id: "fa7e010d-0111-486e-8d65-4df09e8c41c8",
      sku: "620671914",
      sku_detail: "Okanagan Premium Peach 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CIDER",
      style: "CIDER",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "1c440ab2-f18b-42cf-b5f6-6cf7a4817c8d",
      experiment_product_id: "fb4e9aba-248b-4f40-9cfa-25f1b4a90314",
      sku: "628000000000",
      sku_detail: "NUDE VODKA SODA STRWBRRYKIWI 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "SODA",
      style: "SODA",
      dos_multiplier: "0.219000000000000",
      manufacturer: "Orchard City",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "4420db5c-ab7c-4398-b4d9-1ea750f03657",
      experiment_product_id: "fb70cccd-b70c-4f51-b5fb-9212ff8e8802",
      sku: "6206720120",
      sku_detail: "Kokanee 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "CORE",
      style: "CORE",
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "8387d20a-7b34-4b0f-a7bb-4f3ebfc23680",
      experiment_product_id: "fbbee8dc-76ef-40be-820d-e816d1c9a3cd",
      sku: "123123196",
      sku_detail: "Colt 45 Malt 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 0,
      segment: "VALUE",
      style: "VALUE",
      dos_multiplier: "0.017109375000000",
      manufacturer: "Sleeman",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      product_id: "3cf83f6e-6850-4b27-816b-0e2feed9bee3",
      experiment_product_id: "ff3b649f-6221-4fad-bd2c-88e4b6cf8ec1",
      sku: "8580540019",
      sku_detail: "Red Truck IPA 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 0,
      segment: "CRAFT",
      style: "CRAFT",
      dos_multiplier: "0.486666666666667",
      manufacturer: "Red Truck Brewing",
      pack_quantity: 8,
      pack_size: 355,
    },
  ],
};

export const PRODUCT_RESPONSE: any = {
  products: [
    {
      id: "003ac400-735b-4af3-b96a-1ab47ef72902",
      product_id: "6fb42ece-913d-4801-ba8d-1126337d5976",
      experiment_product_id: "003ac400-735b-4af3-b96a-1ab47ef72902",
      sku: "10265591",
      sku_detail: "Pyur Black Cherry 6/0.355 Can",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "SODA",
      style: "SODA",
      door: 2,
      shelf: 1,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 2,
        z: 1,
      },
      position: {
        x: {
          start: 0,
          end: 7.9,
        },
        y: {
          start: 0,
          end: 9.8,
        },
        z: {
          start: 0,
          end: 5.3,
        },
      },
      dos_multiplier: "0.026385542168675",
      manufacturer: "Northam Group",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "00f03638-e85b-4cdb-9799-417b8e618a7b",
      product_id: "2a6f4a17-9831-4dbc-ad0b-87bea2c27e7b",
      experiment_product_id: "00f03638-e85b-4cdb-9799-417b8e618a7b",
      sku: "620673973",
      sku_detail: "Rolling Rock 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 1,
      segment: "VALUE",
      style: "VALUE",
      door: 2,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 5.3,
          end: 18.3,
        },
        y: {
          start: 0,
          end: 7.9,
        },
        z: {
          start: 0,
          end: 5,
        },
      },
      dos_multiplier: "0.912500000000000",
      manufacturer: "Labatt",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      id: "01350b50-43f0-4c75-8541-4bd99ef24657",
      product_id: "227d1fe5-5cf4-495b-82ad-f7724a4f1eab",
      experiment_product_id: "01350b50-43f0-4c75-8541-4bd99ef24657",
      sku: "55840182",
      sku_detail: "Field House Apricot Lemon Blanche 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 1,
      segment: "CRAFT",
      style: "CRAFT",
      door: 2,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 18.3,
          end: 23.6,
        },
        y: {
          start: 0,
          end: 6.3,
        },
        z: {
          start: 0,
          end: 5.3,
        },
      },
      dos_multiplier: "0.132727272727273",
      manufacturer: "Field House Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      id: "01e28f96-c3be-4f59-9da6-09ac5e50253e",
      product_id: "25e4bb93-ba72-4e3a-b6be-df56a992bffc",
      experiment_product_id: "01e28f96-c3be-4f59-9da6-09ac5e50253e",
      sku: "6284121234",
      sku_detail: "TWIN SAILS BREWING DAT JEUCE 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 1,
      segment: "CRAFT",
      style: "CRAFT",
      door: 2,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 39.4,
          end: 44.7,
        },
        y: {
          start: 0,
          end: 6.3,
        },
        z: {
          start: 0,
          end: 5.3,
        },
      },
      dos_multiplier: "0.063478260869565",
      manufacturer: "Twin Sails Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      id: "04bd9785-1692-4a03-9552-021618408a51",
      product_id: "95edf31a-d55b-403e-955b-00255ebe0732",
      experiment_product_id: "04bd9785-1692-4a03-9552-021618408a51",
      sku: "3230244849",
      sku_detail: "NUDE VODKA SODA BLK CHERRY 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "SODA",
      style: "SODA",
      door: 2,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 2,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 23.6,
          end: 39.4,
        },
        y: {
          start: 0,
          end: 4.9,
        },
        z: {
          start: 0,
          end: 5.3,
        },
      },
      dos_multiplier: "0.219000000000000",
      manufacturer: "Orchard City",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "069e6492-35d3-43e9-a526-9de7d8343600",
      product_id: "0a1316fa-e3ef-4f08-bf5d-57033f1ebedf",
      experiment_product_id: "6a3589c2-f5f4-4b75-8926-a6af1f7ce3e3",
      sku: "12402368",
      sku_detail: "Straight & Narrow Variety Pack 12/355C",
      width: "9.3",
      height: "6.3",
      depth: "6.9",
      mapping_count: 2,
      segment: "COCKTAIL",
      style: "COCKTAIL",
      door: 4,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 2,
        y: 2,
        z: 3,
      },
      position: {
        x: {
          start: 15.8,
          end: 34.4,
        },
        y: {
          start: 0,
          end: 12.6,
        },
        z: {
          start: 0,
          end: 20.700000000000003,
        },
      },
      dos_multiplier: "0.182500000000000",
      manufacturer: "Victoria Distillers",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      id: "06b94b99-40ca-4b49-b73b-586d085ef1d6",
      product_id: "fa86421d-58c2-4252-8a5a-69cb6b8a6df6",
      experiment_product_id: "06b94b99-40ca-4b49-b73b-586d085ef1d6",
      sku: "62699023498",
      sku_detail: "Parallel 49 Jerkface 9000 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "CRAFT",
      style: "CRAFT",
      door: 4,
      shelf: 3,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 15.8,
          end: 23.7,
        },
        y: {
          start: 0,
          end: 4.9,
        },
        z: {
          start: 0,
          end: 5.3,
        },
      },
      dos_multiplier: "0.365000000000000",
      manufacturer: "Parallel 49 Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "06e8b759-adab-4d80-8feb-745bb85ec61e",
      product_id: "3582088e-8a31-4d50-bfa6-86d140cbf675",
      experiment_product_id: "06e8b759-adab-4d80-8feb-745bb85ec61e",
      sku: "5691040311",
      sku_detail: "Pabst Blue Ribbon 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "VALUE",
      style: "VALUE",
      door: 3,
      shelf: 1,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 36.5,
          end: 44.4,
        },
        y: {
          start: 0,
          end: 4.9,
        },
        z: {
          start: 0,
          end: 5.3,
        },
      },
      dos_multiplier: "0.040555555555556",
      manufacturer: "Sleeman",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "0738911b-268e-41b1-b015-46feb7131662",
      product_id: "b30d8883-25c5-4023-b687-3b746be8e07f",
      experiment_product_id: "0738911b-268e-41b1-b015-46feb7131662",
      sku: "14105383",
      sku_detail: "Four Winds Original Series Mixer 8/355C",
      width: "10.4",
      height: "6.2",
      depth: "5.2",
      mapping_count: 1,
      segment: "CRAFT",
      style: "CRAFT",
      door: 2,
      shelf: 3,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 15.8,
          end: 26.2,
        },
        y: {
          start: 0,
          end: 6.2,
        },
        z: {
          start: 0,
          end: 5.2,
        },
      },
      dos_multiplier: "0.324444444444444",
      manufacturer: "Four Winds Brewing",
      pack_quantity: 8,
      pack_size: 473,
    },
    {
      id: "0b11c3fe-94a7-45f7-99e5-ad644f800c61",
      product_id: "f5f6551b-36d4-47cc-aebe-5243bcad9db7",
      experiment_product_id: "0b11c3fe-94a7-45f7-99e5-ad644f800c61",
      sku: "6206733545",
      sku_detail: "BUDWEISER 8/355C",
      width: "7.9",
      height: "4.9",
      depth: "7.3",
      mapping_count: 1,
      segment: "CORE",
      style: "CORE",
      door: 4,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 7.9,
          end: 15.8,
        },
        y: {
          start: 0,
          end: 4.9,
        },
        z: {
          start: 0,
          end: 7.3,
        },
      },
      dos_multiplier: "0.486666666666667",
      manufacturer: "Labatt",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      id: "0b88b059-f710-492a-82bd-29fc086d1264",
      product_id: "2f9c1cd5-5902-4871-8819-8b16b22b8085",
      experiment_product_id: "0b88b059-f710-492a-82bd-29fc086d1264",
      sku: "74413185",
      sku_detail: "Tiger Shark Citra Pale Ale 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "CRAFT",
      style: "CRAFT",
      door: 3,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 20.9,
          end: 28.8,
        },
        y: {
          start: 0,
          end: 4.9,
        },
        z: {
          start: 0,
          end: 5.3,
        },
      },
      dos_multiplier: "0.136875000000000",
      manufacturer: "Phillips Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "0bc07efa-edf4-4a23-873e-5aa6bcd56e0d",
      product_id: "42b194c3-ab1a-4256-820b-c38877a30d85",
      experiment_product_id: "0bc07efa-edf4-4a23-873e-5aa6bcd56e0d",
      sku: "93042981",
      sku_detail: "Mikes Hard Iced Tea 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 1,
      segment: "TEA",
      style: "TEA",
      door: 2,
      shelf: 1,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 7.9,
          end: 18.5,
        },
        y: {
          start: 0,
          end: 7.9,
        },
        z: {
          start: 0,
          end: 5,
        },
      },
      dos_multiplier: "0.438000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      id: "0eb31377-467f-4869-9550-85c0ed392ecf",
      product_id: "e5927e22-7ee4-4108-83df-c288619c453f",
      experiment_product_id: "0eb31377-467f-4869-9550-85c0ed392ecf",
      sku: "9367723",
      sku_detail: "MIKES PLAYBACK MIXER 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 1,
      segment: "COOLER",
      style: "COOLER",
      door: 2,
      shelf: 1,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 18.5,
          end: 29.1,
        },
        y: {
          start: 0,
          end: 7.9,
        },
        z: {
          start: 0,
          end: 5,
        },
      },
      dos_multiplier: "0.438000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      id: "0ec9a0b7-a060-4d0b-bfcf-b67e7eadae18",
      product_id: "53ea443b-ad8a-4e82-9386-a92283c4c51f",
      experiment_product_id: "0ec9a0b7-a060-4d0b-bfcf-b67e7eadae18",
      sku: "6206733537",
      sku_detail: "Budweiser 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 1,
      segment: "CORE",
      style: "CORE",
      door: 3,
      shelf: 1,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 0,
          end: 13,
        },
        y: {
          start: 0,
          end: 7.9,
        },
        z: {
          start: 0,
          end: 5,
        },
      },
      dos_multiplier: "0.195535714285714",
      manufacturer: "Labatt",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      id: "10534182-ce62-49c8-ba4a-4c8f75ff5554",
      product_id: "ab565b6c-96ca-417c-af57-d0ec90b09088",
      experiment_product_id: "10534182-ce62-49c8-ba4a-4c8f75ff5554",
      sku: "134134134",
      sku_detail: "NUDE GIN SODA LEMON 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "SODA",
      style: "SODA",
      door: 3,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 3,
        z: 1,
      },
      position: {
        x: {
          start: 0,
          end: 7.9,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 5.3,
        },
      },
      dos_multiplier: "0.045625000000000",
      manufacturer: "Orchard City",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "12f8ff2a-bc86-4c13-b638-7f4459236e6e",
      product_id: "ef0f6f2b-26d7-46c7-a196-ce9f1f3fedf7",
      experiment_product_id: "12f8ff2a-bc86-4c13-b638-7f4459236e6e",
      sku: "8580540014",
      sku_detail: "Red Truck Lager 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 1,
      segment: "CRAFT",
      style: "CRAFT",
      door: 3,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 28.8,
          end: 39.2,
        },
        y: {
          start: 0,
          end: 4.8,
        },
        z: {
          start: 0,
          end: 5.2,
        },
      },
      dos_multiplier: "0.146000000000000",
      manufacturer: "Red Truck Brewing",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      id: "199ecc2a-bff9-4206-bcbd-225b17263520",
      product_id: "7f1527ac-1a02-4aa9-8f90-7caff94e3b36",
      experiment_product_id: "873799c9-1b71-4ed6-aacc-94595d19145b",
      sku: "750000000000",
      sku_detail: "Corona Extra 6/330B",
      width: "7.2",
      height: "9.1",
      depth: "4.9",
      mapping_count: 1,
      segment: "IMPORT",
      style: "IMPORT",
      door: 2,
      shelf: 1,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 2,
        y: 1,
        z: 4,
      },
      position: {
        x: {
          start: 29.1,
          end: 43.5,
        },
        y: {
          start: 0,
          end: 9.1,
        },
        z: {
          start: 0,
          end: 19.6,
        },
      },
      dos_multiplier: "0.045625000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 330,
    },
    {
      id: "1bcb505d-6de4-42e9-8b09-c439547c2fd0",
      product_id: "435787aa-113d-46fc-abb6-4f527428351a",
      experiment_product_id: "773827d0-56e1-4bf5-a70c-743de8c18c84",
      sku: "65481278",
      sku_detail: "Corona Tropical Mixer 12 Can",
      width: "4.3",
      height: "12.0",
      depth: "4.3",
      mapping_count: 1,
      segment: "SELTZER",
      style: "SELTZER",
      door: 3,
      shelf: 1,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 3,
        y: 1,
        z: 5,
      },
      position: {
        x: {
          start: 13,
          end: 25.9,
        },
        y: {
          start: 0,
          end: 12,
        },
        z: {
          start: 0,
          end: 21.5,
        },
      },
      dos_multiplier: "0.438000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      id: "1f13d4b0-629f-4525-91aa-b08ce323bc3c",
      product_id: "be32fddb-c3d7-4572-8332-dde72142d84d",
      experiment_product_id: "cc3c81c9-22f1-4070-b753-decb8f8da23a",
      sku: "81827800349",
      sku_detail: "Phillips Glitter Bomb 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 2,
      segment: "CRAFT",
      style: "CRAFT",
      door: 2,
      shelf: 3,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 7.9,
          end: 15.8,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.219000000000000",
      manufacturer: "Phillips Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "1f8e6304-6c11-4de8-8188-6b20c083450d",
      product_id: "be32fddb-c3d7-4572-8332-dde72142d84d",
      experiment_product_id: "cc3c81c9-22f1-4070-b753-decb8f8da23a",
      sku: "81827800349",
      sku_detail: "Phillips Glitter Bomb 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 2,
      segment: "CRAFT",
      style: "CRAFT",
      door: 1,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 2,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 13,
          end: 28.8,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.219000000000000",
      manufacturer: "Phillips Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "33f9359d-87fb-4709-8a63-a4b8853669d0",
      product_id: "ade94390-4ab3-4ff6-b51c-0e59212b15b6",
      experiment_product_id: "d045e897-9e9b-4b6b-87d0-1dc0c60df6f9",
      sku: "81827800123",
      sku_detail: "Phillips Pilsner 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "12.0",
      mapping_count: 1,
      segment: "CRAFT",
      style: "CRAFT",
      door: 2,
      shelf: 3,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 2,
        z: 2,
      },
      position: {
        x: {
          start: 0,
          end: 7.9,
        },
        y: {
          start: 0,
          end: 9.8,
        },
        z: {
          start: 0,
          end: 24,
        },
      },
      dos_multiplier: "0.547500000000000",
      manufacturer: "Phillips Brewing",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "38841d23-2a14-4b38-a05e-906ab42c994d",
      product_id: "9b78d7c7-b473-4dab-ae53-07322ff890af",
      experiment_product_id: "4a240c89-de4c-4a12-bf74-ff19298af7d4",
      sku: "85531500012",
      sku_detail: "Organic Lager 6/341B",
      width: "7.7",
      height: "8.9",
      depth: "5.1",
      mapping_count: 1,
      segment: "CORE+",
      style: "CORE+",
      door: 5,
      shelf: 3,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 2,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 7.7,
        },
        y: {
          start: 0,
          end: 17.8,
        },
        z: {
          start: 0,
          end: 20.4,
        },
      },
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 341,
    },
    {
      id: "3c1ba24a-5c2c-438b-8f21-d087ca372cd7",
      product_id: "fe08c69f-f2cb-4dbf-94a2-b609a5edece9",
      experiment_product_id: "71f20d4f-0dd8-4931-a542-53a4b6fb5afd",
      sku: "48531152",
      sku_detail: "Mike's Hard Peach Fuzz 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "COOLER",
      style: "COOLER",
      door: 4,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 2,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 15.8,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "4e5b8941-59c8-49f9-b5a0-e69fb0a7dd47",
      product_id: "cb1e254b-b6f2-48df-ba5a-9544fe8b3cf3",
      experiment_product_id: "6079b5af-2a2e-4473-8b11-407d72a282d5",
      sku: "16316751",
      sku_detail: "Blue Buck Ale 6/355C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 1,
      segment: "CRAFT",
      style: "CRAFT",
      door: 2,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 2,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 5.3,
        },
        y: {
          start: 0,
          end: 12.6,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.121666666666667",
      manufacturer: "Phillips Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      id: "55e3145f-2a37-4a30-871b-f5623295e3eb",
      product_id: "4418e945-1b51-4983-b6f0-f5d9ae8f1943",
      experiment_product_id: "b3bc3ba9-18ef-43e5-8373-496e66066137",
      sku: "58521610",
      sku_detail: "PALM BAY RAINBOW TWST 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "COOLER",
      style: "COOLER",
      door: 3,
      shelf: 3,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 2,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 15.8,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "67ade16c-4c1e-4204-92a7-ce8beef477dc",
      product_id: "616f7695-8f83-4fdc-91a2-b4cdbc500b73",
      experiment_product_id: "75d02ff5-3df4-49fe-9780-e369a590d0b2",
      sku: "83820123609",
      sku_detail: "Guinness Draught 4/440C",
      width: "5.3",
      height: "6.7",
      depth: "5.3",
      mapping_count: 1,
      segment: "IMPORT",
      style: "IMPORT",
      door: 2,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 2,
        y: 2,
        z: 4,
      },
      position: {
        x: {
          start: 10.6,
          end: 21.2,
        },
        y: {
          start: 0,
          end: 13.4,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.041714285714286",
      manufacturer: "Diageo",
      pack_quantity: 4,
      pack_size: 440,
    },
    {
      id: "6a3589c2-f5f4-4b75-8926-a6af1f7ce3e3",
      product_id: "0a1316fa-e3ef-4f08-bf5d-57033f1ebedf",
      experiment_product_id: "6a3589c2-f5f4-4b75-8926-a6af1f7ce3e3",
      sku: "12402368",
      sku_detail: "Straight & Narrow Variety Pack 12/355C",
      width: "9.3",
      height: "6.3",
      depth: "6.9",
      mapping_count: 2,
      segment: "COCKTAIL",
      style: "COCKTAIL",
      door: 1,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 7.9,
          end: 17.2,
        },
        y: {
          start: 0,
          end: 6.3,
        },
        z: {
          start: 0,
          end: 6.9,
        },
      },
      dos_multiplier: "0.182500000000000",
      manufacturer: "Victoria Distillers",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      id: "7194dc85-dfbe-43a7-9522-4d0f32c49a98",
      product_id: "2fe80ef1-b3e6-49f1-b844-38576eb110f8",
      experiment_product_id: "73b83025-b9c5-4792-8f3e-115e2674b7f7",
      sku: "909000000000",
      sku_detail: "Driftwood Brewery Raised By Wolves IPA 4/473C",
      width: "5.3",
      height: "6.3",
      depth: "5.3",
      mapping_count: 1,
      segment: "CRAFT",
      style: "CRAFT",
      door: 3,
      shelf: 1,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 2,
        y: 2,
        z: 4,
      },
      position: {
        x: {
          start: 25.9,
          end: 36.5,
        },
        y: {
          start: 0,
          end: 12.6,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.073000000000000",
      manufacturer: "Driftwood Brewing",
      pack_quantity: 4,
      pack_size: 473,
    },
    {
      id: "734a9e36-43e3-4cc3-8c07-8857a707107b",
      product_id: "bfd58d1a-662b-41e0-adf9-323084c052a1",
      experiment_product_id: "1187fc1c-0f87-48bc-be10-54d6875a2652",
      sku: "7745580028",
      sku_detail: "Pacific Western Cariboo Malt 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "VALUE",
      style: "VALUE",
      door: 1,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 7.9,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.009909502262443",
      manufacturer: "Pacific Western",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "76c34a65-8bd7-431d-a422-23dd5370b3db",
      product_id: "d5666917-65ac-4c23-9803-b31efaa972bd",
      experiment_product_id: "b15a864a-6d0e-480a-bbbe-8d58afe3e21a",
      sku: "6206737618",
      sku_detail: "Michelob Ultra 15/355C",
      width: "10.5",
      height: "6.3",
      depth: "4.9",
      mapping_count: 1,
      segment: "CORE+",
      style: "CORE+",
      door: 4,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 2,
        z: 4,
      },
      position: {
        x: {
          start: 15.8,
          end: 26.3,
        },
        y: {
          start: 0,
          end: 12.6,
        },
        z: {
          start: 0,
          end: 19.6,
        },
      },
      dos_multiplier: "0.912500000000000",
      manufacturer: "Labatt",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      id: "7b2f05c0-f28b-48f7-a11c-67b5ba98c4b5",
      product_id: "d0990c9a-2af3-4065-8f25-eb9f3a555c3b",
      experiment_product_id: "da82750e-9cca-4ab3-b199-45d5b4913bcc",
      sku: "569104031",
      sku_detail: "Pabst Blue Ribbon 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 1,
      segment: "VALUE",
      style: "VALUE",
      door: 2,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 21.2,
          end: 31.6,
        },
        y: {
          start: 0,
          end: 14.399999999999999,
        },
        z: {
          start: 0,
          end: 20.8,
        },
      },
      dos_multiplier: "0.024745762711864",
      manufacturer: "Sleeman",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      id: "826fd696-b09f-48dc-9504-b5863ca9394c",
      product_id: "6ae4e247-17f9-4dfa-a3d9-25717d451e26",
      experiment_product_id: "9c771f44-37dc-4195-875d-944dd114adb0",
      sku: "84340300016",
      sku_detail: "Yes Guy Hard Ice Tea 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "TEA",
      style: "TEA",
      door: 3,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 2,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 15.8,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.365000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "a5528cf2-105e-4090-898a-be6d10a83a5b",
      product_id: "34210611-96c7-4358-b29c-729732b41b96",
      experiment_product_id: "9e26ddc2-4e9e-4c15-8aba-912c09587651",
      sku: "77946960008",
      sku_detail: "WHITE CLAW RUBY GRAPEFRUIT 6/355C",
      width: "7.0",
      height: "6.3",
      depth: "4.6",
      mapping_count: 1,
      segment: "SELTZER",
      style: "SELTZER",
      door: 5,
      shelf: 3,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 2,
        z: 5,
      },
      position: {
        x: {
          start: 7.7,
          end: 14.7,
        },
        y: {
          start: 0,
          end: 12.6,
        },
        z: {
          start: 0,
          end: 23,
        },
      },
      dos_multiplier: "0.042115384615385",
      manufacturer: "Mark Anthony",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "b99f2e8e-f944-4c63-9987-4023748dc5a7",
      product_id: "223de6a7-f757-4c34-9173-87a89836ffdc",
      experiment_product_id: "81155584-5c05-4c20-81cd-709ff8bea794",
      sku: "28971129",
      sku_detail: "Corona Tropical Raspberry Lemon 6 Can",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 2,
      segment: "SELTZER",
      style: "SELTZER",
      door: 4,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 7.9,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "c47a4243-10c9-4462-9c76-56e36653b2d9",
      product_id: "d5971163-e9e8-4c99-b18d-7b116b8156f1",
      experiment_product_id: "c47a4243-10c9-4462-9c76-56e36653b2d9",
      sku: "5691040180",
      sku_detail: "Old Milwaukee 8/355C",
      width: "10.4",
      height: "4.8",
      depth: "5.2",
      mapping_count: 1,
      segment: "VALUE",
      style: "VALUE",
      door: 1,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 1,
      },
      position: {
        x: {
          start: 17.2,
          end: 27.6,
        },
        y: {
          start: 0,
          end: 4.8,
        },
        z: {
          start: 0,
          end: 5.2,
        },
      },
      dos_multiplier: "0.022461538461538",
      manufacturer: "Sleeman",
      pack_quantity: 8,
      pack_size: 355,
    },
    {
      id: "c4afefbe-70e1-4f66-979d-3613a57af6e2",
      product_id: "0eae5459-6103-4ba4-b598-ae9a367f84d1",
      experiment_product_id: "21cc5a46-34c3-4791-9995-de8f7f4e8036",
      sku: "620671917",
      sku_detail: "OKANAGAN PREM ORCH PEACH CIDER 4/500C",
      width: "4.3",
      height: "12.0",
      depth: "4.3",
      mapping_count: 2,
      segment: "CIDER",
      style: "CIDER",
      door: 1,
      shelf: 3,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 5,
      },
      position: {
        x: {
          start: 0,
          end: 4.3,
        },
        y: {
          start: 0,
          end: 12,
        },
        z: {
          start: 0,
          end: 21.5,
        },
      },
      dos_multiplier: "0.018250000000000",
      manufacturer: "Labatt",
      pack_quantity: 1,
      pack_size: 2000,
    },
    {
      id: "c5afd968-c3cf-4a82-b969-1b2cd830256e",
      product_id: "223de6a7-f757-4c34-9173-87a89836ffdc",
      experiment_product_id: "81155584-5c05-4c20-81cd-709ff8bea794",
      sku: "28971129",
      sku_detail: "Corona Tropical Raspberry Lemon 6 Can",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 2,
      segment: "SELTZER",
      style: "SELTZER",
      door: 5,
      shelf: 1,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 7.9,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.219000000000000",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "d0cfc762-6eef-4f9c-93af-a5d463198af0",
      product_id: "be146fb3-5302-4aaf-8d4b-3103f368ef75",
      experiment_product_id: "a38dc712-5eae-4c47-97a5-8736a09f3cfe",
      sku: "5691040315",
      sku_detail: "Pabst Blue Ribbon 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 3,
      segment: "VALUE",
      style: "VALUE",
      door: 1,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 2,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 13,
        },
        y: {
          start: 0,
          end: 15.8,
        },
        z: {
          start: 0,
          end: 20,
        },
      },
      dos_multiplier: "0.045625000000000",
      manufacturer: "Sleeman",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      id: "d1ddc738-847f-44c3-be69-e57c8428625c",
      product_id: "be146fb3-5302-4aaf-8d4b-3103f368ef75",
      experiment_product_id: "a38dc712-5eae-4c47-97a5-8736a09f3cfe",
      sku: "5691040315",
      sku_detail: "Pabst Blue Ribbon 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 3,
      segment: "VALUE",
      style: "VALUE",
      door: 1,
      shelf: 3,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 2,
        z: 4,
      },
      position: {
        x: {
          start: 4.3,
          end: 17.3,
        },
        y: {
          start: 0,
          end: 15.8,
        },
        z: {
          start: 0,
          end: 20,
        },
      },
      dos_multiplier: "0.045625000000000",
      manufacturer: "Sleeman",
      pack_quantity: 15,
      pack_size: 355,
    },
    {
      id: "d51dcbdd-5d47-4e26-829b-3a1272b4ab85",
      product_id: "a9c97ea7-c8d1-4564-8c4c-0225cf04dd81",
      experiment_product_id: "e51c54a0-fe62-444c-ace1-395c44ec6f4e",
      sku: "6206720283",
      sku_detail: "Stanley Park Concession Pack 12/355C",
      width: "10.6",
      height: "7.9",
      depth: "5.0",
      mapping_count: 1,
      segment: "CRAFT",
      style: "CRAFT",
      door: 2,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 2,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 10.6,
        },
        y: {
          start: 0,
          end: 15.8,
        },
        z: {
          start: 0,
          end: 20,
        },
      },
      dos_multiplier: "0.730000000000000",
      manufacturer: "Labatt",
      pack_quantity: 12,
      pack_size: 355,
    },
    {
      id: "db804265-db53-459e-adbc-0b2338c3abf7",
      product_id: "53de39b4-ebc7-4a1a-b22f-0ec388a6b78e",
      experiment_product_id: "357535f5-3946-46c2-b0f6-1cd46d52b923",
      sku: "8745580011",
      sku_detail: "Pacific Western Cariboo Genuine Draft 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "VALUE",
      style: "VALUE",
      door: 4,
      shelf: 1,
      orientation: "top",
      rotation: "zero",
      facing: {
        x: 2,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 7.9,
          end: 23.7,
        },
        y: {
          start: 0,
          end: 15.899999999999999,
        },
        z: {
          start: 0,
          end: 19.6,
        },
      },
      dos_multiplier: "0.024333333333333",
      manufacturer: "Pacific Western",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "dd2ebbe3-a045-4b79-8783-40fb4d7f6633",
      product_id: "04d7716e-fba2-4fe1-8368-cbe6497c68be",
      experiment_product_id: "6b88d56d-6dbc-4e0a-a072-f687ac519e34",
      sku: "35353535",
      sku_detail: "MOTT'S CLAMATO CAESAR PICKLBN 6/341B",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "CAESAR",
      style: "CAESAR",
      door: 5,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 7.9,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.073000000000000",
      manufacturer: "Canada Dry Mott's Inc.",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "dd4be2e7-259a-4ce4-a8ff-aead022e9818",
      product_id: "bf925a5e-e9c2-4184-bf65-1c276600aacc",
      experiment_product_id: "fcb26b00-c9c8-45f1-8165-ebb28f9035ef",
      sku: "620673663",
      sku_detail: "Wildcat Strong 6/355C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "VALUE",
      style: "VALUE",
      door: 4,
      shelf: 1,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 7.9,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.038421052631579",
      manufacturer: "Labatt",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "e86d7a1f-04c9-4c4a-b5c9-c06d1127b460",
      product_id: "9c289c1e-8d76-4926-a08a-8336afa96641",
      experiment_product_id: "a77e032e-6cd8-48c7-bfc1-15dffdb83ab9",
      sku: "7269901877",
      sku_detail: "HEY YALL STHRN HARD ICETEA 6/341C",
      width: "7.9",
      height: "4.9",
      depth: "5.3",
      mapping_count: 1,
      segment: "TEA",
      style: "TEA",
      door: 4,
      shelf: 3,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 2,
        y: 3,
        z: 4,
      },
      position: {
        x: {
          start: 0,
          end: 15.8,
        },
        y: {
          start: 0,
          end: 14.700000000000001,
        },
        z: {
          start: 0,
          end: 21.2,
        },
      },
      dos_multiplier: "0.025465116279070",
      manufacturer: "Northam Group",
      pack_quantity: 6,
      pack_size: 355,
    },
    {
      id: "f4786d07-e308-469a-8c47-f76878779eb4",
      product_id: "ba240730-bdf3-4ad6-b417-c33a5c9b7c35",
      experiment_product_id: "7ae6a542-4ad0-468a-a989-936b65d61075",
      sku: "50708369170",
      sku_detail: "BLACK FLY TEQUILA MARGARITA 4/400B",
      width: "2.6",
      height: "11.8",
      depth: "2.6",
      mapping_count: 1,
      segment: "COOLER",
      style: "COOLER",
      door: 5,
      shelf: 2,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 1,
        z: 9,
      },
      position: {
        x: {
          start: 0,
          end: 2.6,
        },
        y: {
          start: 0,
          end: 11.8,
        },
        z: {
          start: 0,
          end: 23.400000000000002,
        },
      },
      dos_multiplier: "0.091250000000000",
      manufacturer: "BLACKFLY",
      pack_quantity: 4,
      pack_size: 400,
    },
    {
      id: "fe110ee9-55b5-4308-9f28-9611f205d03c",
      product_id: "be146fb3-5302-4aaf-8d4b-3103f368ef75",
      experiment_product_id: "a38dc712-5eae-4c47-97a5-8736a09f3cfe",
      sku: "5691040315",
      sku_detail: "Pabst Blue Ribbon 15/355C",
      width: "13.0",
      height: "7.9",
      depth: "5.0",
      mapping_count: 3,
      segment: "VALUE",
      style: "VALUE",
      door: 3,
      shelf: 4,
      orientation: "front",
      rotation: "zero",
      facing: {
        x: 1,
        y: 2,
        z: 4,
      },
      position: {
        x: {
          start: 7.9,
          end: 20.9,
        },
        y: {
          start: 0,
          end: 15.8,
        },
        z: {
          start: 0,
          end: 20,
        },
      },
      dos_multiplier: "0.045625000000000",
      manufacturer: "Sleeman",
      pack_quantity: 15,
      pack_size: 355,
    },
  ],
  leftout: true,
};

export const FILTER_RESPONSE: any = {
  filter: {
    segments: [
      "CAESAR",
      "CIDER",
      "COCKTAIL",
      "COOLER",
      "CORE",
      "CORE+",
      "CRAFT",
      "IMPORT",
      "SELTZER",
      "SODA",
      "TEA",
      "VALUE",
    ],
    styles: [
      "CAESAR",
      "CIDER",
      "COCKTAIL",
      "COOLER",
      "CORE",
      "CORE+",
      "CRAFT",
      "IMPORT",
      "SELTZER",
      "SODA",
      "TEA",
      "VALUE",
    ],
    manufacturer: [
      "33 Acres Brewing Co",
      "AQUILINI BRANDS",
      "BLACKFLY",
      "BROKEN LADDER",
      "Backcountry Brewing",
      "Brewhall Beer",
      "Bridge Brewing ",
      "Canada Dry Mott's Inc.",
      "Central City Brewing",
      "Cerveceria Astilleros",
      "Constellation Brands",
      "Craft Collective Brewery",
      "Deep Cove Brewers",
      "Diageo",
      "Driftwood Brewing",
      "Field House Brewing",
      "Four Winds Brewing",
      "Fuggles & Warlock Craftworks",
      "Groupe Omni",
      "Howe Sound ",
      "Labatt",
      "Mariner Brewing",
      "Mark Anthony",
      "Molson",
      "Moody Ales LTD",
      "Northam Brewery",
      "Northam Group",
      "Orchard City",
      "PROVINCIAL SPIRITS LTD.",
      "Pacific Western",
      "Pacific Western Brewing",
      "Parallel 49 Brewing",
      "Parkside Brewery",
      "Patina Brewing",
      "Phillips Brewing",
      "Phillips Brewing ",
      "R & B Brewing",
      "Red Truck Beer Company",
      "Red Truck Brewing",
      "Remix",
      "Side Project",
      "Sleeman",
      "Steamworks Brewing Company",
      "Strange Fellows Brewing",
      "Strathcona Brewery",
      "Strongbow",
      "Twin Sails Brewing",
      "Victoria Distillers",
      "Virtue Brewing",
      "Whistler Brewing",
      "Wildeye brewing",
      "Yellow Dog Brewing",
    ],
    pack_type: ["BOTTLE", "CAN", "CAN SLEEK", "SLEEK"],
  },
};

export const BASIC_SKU_DETAILS: any = [
  {
    label: "Segment",
    translatorLabel: "MY_PLANO.SEGMENT",
    key: "segment",
  },
  {
    label: "Style",
    translatorLabel: "MY_PLANO.STYLE",
    key: "style",
  },
  {
    label: "Manufacturer",
    translatorLabel: "MY_PLANO.MANUFACTURER",
    key: "manufacturer",
  },
  {
    label: "Pack Size",
    translatorLabel: "SYSTEM_RECOMMENDED.PACK_SIZE",
    key: "pack_size",
    decimalMethod: true,
    decimal: 2,
  },
  {
    label: "Pack Type",
    translatorLabel: "ADD_SKU.PACK_TYPE",
    key: "pack_type",
  },
  {
    label: "REVENUE",
    translatorLabel: "BASE_GRID.REVENUE",
    key: "revenue",
    decimalMethod: true,
    decimal: 2,
  },
  {
    label: "Volume",
    translatorLabel: "BASE_GRID.VOLUME",
    key: "volume",
    decimalMethod: true,
    decimal: 2,
  },
  {
    label: "Brand",
    translatorLabel: "MY_PLANO.BRAND",
    key: "brand",
  },
  {
    label: "Reco Facing",
    translatorLabel: "MY_PLANO.RECOMMENDED_FACING_LABEL",
    key: "recommended_facing",
  },
];

export const PACK_VARIANT_DETAILS: any = [
  {
    heading: "",
    formList: [
      {
        label: "Name",
        model: "name",
        isRequired: true,
        type: "dropdown",
        optionList: [
          "default",
          "tray",
          "case",
          "display",
          "alternate",
          "loose",
        ],
      },
    ],
  },
  {
    heading: "Configuration",
    errorKey: "configuration",
    info: "Configure the pack by specifying the number of units horizontally (H), vertically (V), and in depth (D). Total pack quantity is calculated as H * V * D.",
    formList: [
      {
        label: "Horizontal",
        model: "x",
        isRequired: false,
        valueKey: "inner_pack",
        min: 1,
        type: "input",
      },
      {
        label: "Vertical",
        model: "y",
        isRequired: false,
        valueKey: "inner_pack",
        min: 1,
        type: "input",
      },
      {
        label: "Depth",
        model: "z",
        isRequired: false,
        valueKey: "inner_pack",
        min: 1,
        type: "input",
      },
    ],
  },
  {
    heading: "Pack Qty Dimension (inch)",
    errorKey: "dimension",
    info: "Provide the overall dimensions of the pack, including length, height, and depth",
    formList: [
      {
        label: "Length",
        model: "width",
        isRequired: true,
        min: 1,
        type: "input",
      },
      {
        label: "Height",
        model: "height",
        isRequired: true,
        min: 1,
        type: "input",
      },
      {
        label: "Depth",
        model: "depth",
        isRequired: true,
        min: 1,
        type: "input",
      },
    ],
  },
];

export const FACING_ANALYSIS_COLUMNS: any = [
  {
    label: "SKU Name",
    colSpan: "",
    value: "sku_detail",
    valueType: "",
    sortable: true,
    filterType: "input_text",
    filterMatchMode: "contains",
  },
  {
    label: "Manufacturer",
    colSpan: "",
    value: "manufacturer",
    valueType: "",
    sortable: true,
    filterType: "input_text",
    filterMatchMode: "contains",
  },
  {
    label: "Brand",
    value: "brand",
    colSpan: "",
    valueType: "",
    sortable: true,
    filterType: "input_text",
    filterMatchMode: "contains",
  },
  {
    label: "Pack Qty",
    value: "pack_qty",
    colSpan: "",
    valueType: "",
    sortable: true,
    filterType: "input_text",
    filterMatchMode: "contains",
  },
  {
    label: "Style",
    value: "style",
    colSpan: "",
    valueType: "",
    sortable: true,
    filterType: "input_text",
    filterMatchMode: "contains",
  },
  {
    label: "Pack Type",
    value: "pack_type",
    colSpan: "",
    valueType: "",
    sortable: true,
    filterType: "input_text",
    filterMatchMode: "contains",
  },
  {
    label: "Pack Size",
    value: "pack_size",
    colSpan: "",
    valueType: "",
    sortable: true,
    filterType: "input_text",
    filterMatchMode: "contains",
  },
  {
    label: "Ptc Segment",
    value: "ptc_segment",
    colSpan: "",
    valueType: "",
    sortable: true,
    filterType: "input_text",
    filterMatchMode: "contains",
  },
  {
    label: "SKU Basket",
    value: "sku_plano_presence",
    colSpan: "",
    valueType: "",
    sortable: true,
    filterType: "input_text",
    filterMatchMode: "contains",
  },
  {
    label: "Pre Plano",
    spanText: "(A)",
    parseFloat: true,
    colSpan: "",
    value: "Pre Plano (A)",
    valueType: "",
    sortable: true,
    filterType: "comparison_filter",
    filterMatchMode: "contains",
  },
  {
    label: "System Reco.",
    spanText: "(B)",
    colSpan: "",
    parseFloat: true,
    value: "System Reco. (B)",
    valueType: "",
    sortable: true,
    filterType: "comparison_filter",
    filterMatchMode: "contains",
  },
  {
    label: "Difference",
    spanText: "(B-A) (%)",
    highlightData: true,
    parseFloat: true,
    colSpan: "",
    value: "Difference (B-A)",
    showDifferencePercentage: true,
    valueType: "",
    sortable: true,
    filterType: "comparison_filter",
    filterMatchMode: "contains",
  },
  {
    label: "New Plano",
    spanText: "(C)",
    colSpan: "",
    parseFloat: true,
    value: "New Plano (C)",
    valueType: "",
    sortable: true,
    filterType: "comparison_filter",
    filterMatchMode: "contains",
  },
  {
    label: "Difference",
    spanText: "(C-A) (%)",
    parseFloat: true,
    highlightData: true,
    showDifferencePercentage: true,
    colSpan: "",
    value: "Difference (C-A)",
    valueType: "",
    sortable: true,
    filterType: "comparison_filter",
    filterMatchMode: "contains",
  },
];

export const FACING_ANALYSIS_SELECT_OPTIONS: any = [
  {
    label: "Manufacturer",
    value: "manufacturer",
  },
  {
    label: "Brand",
    value: "brand",
  },
  {
    label: "Pack Qty",
    value: "pack_qty",
  },
  {
    label: "Style",
    value: "style",
  },
  {
    label: "Pack Type",
    value: "pack_type",
  },
  {
    label: "Pack Size",
    value: "pack_size",
  },
  {
    label: "Ptc Segment",
    value: "ptc_segment",
  },
  {
    label: 'SKU Basket',
    value: 'sku_plano_presence',
  }
];

export const FACING_ANALYSIS_NUMERIC_FILTER: any = [
  {
    label: "<=",
    value: "lte",
  },
  {
    label: ">=",
    value: "gte",
  },
  {
    label: "=",
    value: "equals",
  },
];

export const PRODUCT_LIBRARY_DETAILS: any = {
  tableList: [
    {
      label: "SKU ID",
      value: "sku",
      sortable: false,
      frozenColumn: true,
      alignFrozen: "left",
      filterType: "input_text",
      filterMatchMode: "contains"
    },
    {
      label: "SKU Name",
      value: "sku_detail",
      sortable: false,
      frozenColumn: true,
      alignFrozen: "left",
      filterType: "input_text",
      filterMatchMode: "contains",
      trimText: true,
    },
    {
      label: "Segment",
      value: "segment",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      trimText: true,
    },
    {
      label: "Style",
      value: "style",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      trimText: true,
    },
    {
      label: "Manufacturer",
      value: "manufacturer",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      trimText: true,
    },
    {
      label: "Brand",
      value: "brand",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      trimText: true,
    },
    {
      label: "Category",
      value: "category",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      trimText: true,
    },
    {
      label: "Pack Type",
      value: "pack_type",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      colMinWidth: true,
    },
    {
      label: "Pack Size",
      value: "pack_size",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      colMinWidth: true,
    },
    {
      label: "Pack Quantity",
      value: "pack_quantity",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      colMinWidth: true,
    },
    {
      label: "Length",
      value: "width",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      colMinWidth: true,
    },
    {
      label: "Height",
      value: "height",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      colMinWidth: true,
    },
    {
      label: "Depth",
      value: "depth",
      sortable: false,
      filterType: "input_text",
      filterMatchMode: "contains",
      colMinWidth: true,
    },
    {
      label: "Action",
      inputType: "action-icons",
      frozenColumn: true,
      colMinWidth: true,
      class: 'flex justify-center items-center',
      actionList: [
        { label: "Add to Exp", key: "add", icon: 'icon-add', disableKey: 'added_to_exp', isLoadingKey: 'isLoading' },
        // { label: "History", key: "history", icon: 'icon-time', disableKey: '', isLoadingKey: '' },
      ],
    },
  ],
};
