import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Table, TableCheckbox, TableLazyLoadEvent, TableSelectAllChangeEvent } from "primeng/table";
import { SkuAge } from "../../core/enums";
import { StateService } from "../../services/state.service";
import { groupBy } from "../../utils";
import { CheckboxChangeEvent } from "primeng/checkbox";
import { LazyLoadEvent } from "primeng/api";
import { KeyValue } from "@angular/common";
import { Router } from "@angular/router";
import { PaginatorModule } from 'primeng/paginator';

@Component({
  selector: "app-pho-table",
  templateUrl: "./pho-table.component.html",
  styleUrls: ["./pho-table.component.scss"],
})
export class PhoTableComponent implements OnInit {
  @Input() tableColumns: any[] = [];
  @Input() tableData: any[] = [];
  @Input() tableActions: any[] = [];
  @Input() dataFrom?: string;
  @Input() skeletonLoader: boolean = false;
  @Input() showPagination: boolean = false;
  @Input() showDynamicPagination: boolean = false;
  @Input() topHeader: any[] = [];
  @Input() showFilters: boolean = false;
  @Input() groupRowsBy: string = "";
  @Input() subHeaderColumns: any[] = [];
  @Input() scrollFlag: boolean = false;
  @Input() scrollHeight: string = "";
  @Input() rowFlag!: number;
  @Input() styleClassName: string = "";
  @Input() virtualScrollFlag: boolean = false;
  @Input() textAlign: string = "";
  @Input() isSelectableTable?: boolean = false;
  @Input() isSelectableTableFrozen: boolean = false;
  @Input() rowsPerPage?: number = 5;
  @Input() rowClick: boolean = false;
  @Input() isVotingClosed: boolean = false;
  @Input() selectionMode: any = "";
  @Input() totalRecords!: number;
  @Input() uniqueKey: string = "";
  @Output() emitTableAction = new EventEmitter<any>();
  @Output() emitTableSelection = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<any>();
  @Output() emitRowSelection = new EventEmitter<any>();
  @Output() emitTableRowClick = new EventEmitter<any>();
  @Output() emitFilter = new EventEmitter<any>();
  @Input() paginationFirst: number = 0;
  @Input() lazyLoad: ((page: number, perPage: number) => void) | null = null;
  @Input() paginationList: any = [];
  @Input() accessType:any;

  selectedItems: any[] = [];
  skeletonData = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  pagination = {
    limit: 5,
    options: [5, 10, 20, 30],
  };

  skuAge = SkuAge;
  objectKeys = Object.keys;
  selectedRow!: any;
  selectFilterOptions: any[] = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
    { label: "Option 4", value: "option4" },
    { label: "Option 5", value: "option5" },
  ];
  @ViewChild("phoTbl") phoTbl!: Table;
  actionColumnList: any = [];
  overlayDetails: any = {};
  lockAssortment: boolean = false;
  expId!: any;
  showEditIcon: boolean = false;
  hoveredRow: any = null;
  filterSuggestions: any[] = [];
  selectedFilterItem: any;
  currentPage: number = 1;

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }
  
  constructor(private stateService: StateService, private router: Router) {
    // syncing assortment steps lock value
    this.stateService.getAssortmentLock.subscribe((newValue: any) => {
      this.setAssortmentStepsLock();
    });
  }

  ngOnInit(): void {
    this.expId = sessionStorage.getItem("expId");
    // locking assortment steps after initiate voting
    this.setAssortmentStepsLock();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.tableData) {
      this.prepareTableFilterOptions(changes.tableData.currentValue);

      this.paginationFirst = 0;

      const savedPage = sessionStorage.getItem('currentPage');
      if (savedPage && this.dataFrom ==='opr') {
        // Convert the page number from string to number
        this.paginationFirst = (+savedPage-1)*10;
      } else {
        this.paginationFirst = 0; // Default to page 1 if no page is stored
      }
      if (this.dataFrom == "simulation-parent-table") {
        this.selectedRow = this.tableData[0] || {};
        this.emitTableSelection.emit({
          type: "row_select",
          data: this.selectedRow,
        });
      }
    }

    if(changes && changes.paginationList) {
      this.pagination = {
        limit: this.paginationList[0],
        options: this.paginationList
      }
      this.rowFlag = this.paginationList[0];
      this.paginationFirst = 0;
    }
    this.selectedItems = [];
    // clearing table on clear filters
    if (changes && changes.showFilters) {
      this.phoTbl?.clear();
    }
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    if (this.lazyLoad){
    let page = (event.first! / event.rows!) + 1;
    this.lazyLoad(page, event.rows!);
    }
  }

  onTableAction(data: any, action: any) {
    if(!this.router.url.includes("ppr-summary"))
    {
    event?.stopPropagation();
    }
    this.emitTableAction.emit({ ...data, ...action });
  }

  onSelectAllRows(event: any) {
    this.emitTableSelection.emit(this.selectedItems);
  }

  checkRow(event: any) {
    this.emitTableSelection.emit(this.selectedItems);
  }
  onPage(event: any) {
    this.changePage.emit({ event });
  }
  toSelectedRow(event: Event, item: any) {
    if (this.dataFrom == "simulation-parent-table") {
      this.selectedRow = item;
      this.emitTableSelection.emit({
        type: "row_select",
        data: item,
      });
    }

    if (this.rowClick) {
      this.emitRowSelection.emit(item);
    }

    if (
      this.dataFrom == "user-voting" ||
      this.dataFrom == "opr" ||
      this.dataFrom == "exe-summary-sku-details"
    ) {
      this.emitTableRowClick.emit({
        type: "row_select",
        data: item,
      });
    }
  }

  getString(input: any) {
    return String(input);
  }

  clear(table: Table) {
    table.clear();
  }

  onTableDropdownChange(selectedValue: any, rowData: any) {
    if (this.dataFrom == "executuve-summary") {
      rowData.recommendation = selectedValue;
    } else {
      rowData.simulation = selectedValue;
    }
    this.emitTableSelection.emit({
      type: "dropdown_change",
      dataFrom: this.dataFrom,
      data: rowData,
    });
  }

  onInputBlur(selectedValue: any, rowData: any) {
    if (selectedValue != rowData.remarks) {
      rowData.remarks = selectedValue;
      this.emitTableSelection.emit({
        type: "input_blur",
        dataFrom: this.dataFrom,
        data: rowData,
      });
    }
  }

  nParseFloat(data: any, val: any) {
    if (
      data["index"] == "% of Total SCL Delisted" ||
      data["index"] == "% of Total Revenue Delisted" ||
      data["index"] == "% of Total Volume Delisted" ||
      data["index"] == "% of SKUs delisted" ||
      data["index"] == "% of Total MACO Delisted"
    ) {
      if (this.isInt(val)) {
        return this.formatNumber(val) + "%";
      } else if (val && typeof val != "string" && !this.isInt(val)) {
        const floatVal: any = this.stateService.nParseFloat(val);
        return this.formatNumber(floatVal) + "%";
      }
    }
    if (typeof val == "string") {
      return val != "" ? val : "-";
    } else if (this.isInt(val)) {
      return this.formatNumber(val);
    } else if (val && typeof val != "string" && !this.isInt(val)) {
      const floatVal: any = this.stateService.nParseFloat(val);
      return this.formatNumber(floatVal);
    } else {
      return val || "-";
    }
  }

  isInt(num: any) {
    return Number(num) === num && num % 1 === 0;
  }

  formatNumber(num: number) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  onCheckedOrUpdated() {
    this.emitRowSelection.emit(this.selectedItems);
  }

  toggleOverlay(actionColumn: any, data: any) {
    this.actionColumnList = actionColumn.actionList;
    this.overlayDetails = data;
  }

  planAction(option: any) {
    this.emitTableAction.emit({ action: option, details: this.overlayDetails });
  }

  prepareTableFilterOptions(response: any) {
    this.tableColumns.map((each: any) => {
      if (each.filterType == "dropdown") {
        const groupByKeys = groupBy(response, each.value);
        if (groupByKeys) {
          each.filterOptions =
            Object.keys(groupByKeys)?.map((each: any) => {
              if (each != "undefined") {
                return { label: each, value: each };
              }
              return;
            }) || [];
        }
      }
    });
  }

  onTableRowClick(event: any) {}

  setAssortmentStepsLock() {
    const lockAssortmentSteps = JSON.parse(
      sessionStorage.getItem("lockAssortment")!
    );
    if (lockAssortmentSteps) {
      this.lockAssortment = lockAssortmentSteps[this.expId!];
    }
  }

  enableEditing(event: Event, item: any) {
    this.hoveredRow = item;
  }

  disableEdititng(event: Event, item: any) {
    this.hoveredRow = item;
  }

  findFilterOptions(event: any, filterOptions: any) {
    if (event) {
      this.filterSuggestions = filterOptions.filter((each: any) => {
        if (each.value.toLowerCase().includes(event.query.toLowerCase())) {
          return each;
        }
      });
    }
  }

  filterTable(event: any, column: any) {
    let matchMode =
      column?.filterType === "input_text" && column?.filterMatchMode
        ? "contains"
        : "equals";
    this.phoTbl.filter(event.value, column?.value, matchMode);
  }

  clearAutocompleteFilters() {
    this.phoTbl.clear();
  }
  getColor(simulation_output: string): string {
    switch (simulation_output.toLowerCase()) {
      case "delist":
        return "red"; // Use standard color names or hex codes
      case "keep":
        return "green";
      case "consult":
        return "#e5b611";
      case "cultivate":
        return "#e5b611";
      default:
        return "black"; // Default color if none matched
    }
  }

  filterSearch(value: string, column: any) {
    this.emitFilter.emit({
      column: column,
      value: value
    })
  }
}
