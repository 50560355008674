import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { WebSocketSubject, webSocket } from "rxjs/webSocket";
import { HttpService } from "./http.service";
import { StateService } from "./state.service";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  private socket$: WebSocketSubject<any> | null = null;
  private planId: string | null = null;
  private socketUrl: string;
  private socketClosedPurposefully: boolean = false;

  // Subjects to handle incoming and outgoing messages
  private incomingMessages$: Subject<any> = new Subject<any>();
  private outgoingMessages$: Subject<any> = new Subject<any>();

  // Reconnection settings
  private reconnectAttempts = 0;
  private readonly maxReconnectAttempts = 6; // Maximum number of reconnection attempts
  private readonly reconnectInterval = 5000; // Basic time between reconnection attempts (in ms)
  private reconnectTimeOutEvent: any = null;

  constructor(
    private stateService: StateService,
    private router: Router,
    private http: HttpService
  ) {
    this.socketUrl = this.stateService.websocketConfig.planogram.webSocketUrl;
  }

  initSubjects() {
    this.incomingMessages$.closed || this.incomingMessages$.complete();
    this.outgoingMessages$.closed || this.outgoingMessages$.complete();

    this.incomingMessages$ = new Subject<any>();
    this.outgoingMessages$ = new Subject<any>();
  }

  closeSubjects() {
    this.incomingMessages$.complete();
    this.outgoingMessages$.complete();
  }

  // Connect to the WebSocket with a specific plan ID and token
  public connect(planId: string, onReconnect: boolean = false): void {
    this.disconnect(false); // Disconnect if already connected
    this.initSubjects(); // Reinitialize subjects

    if (!onReconnect) {
      this.socketClosedPurposefully = false;
    }

    this.planId = planId;
    let token = this.stateService.getToken();

    console.log("Connecting to WebSocket...");
    this.socket$ = webSocket({
      url: this.socketUrl + `?token=${token}`,
      openObserver: {
        next: () => {
          this.socketClosedPurposefully = false; //
          this.reconnectAttempts = 0; // Reset attempts on successful connection
          console.log(`Connected to plan ID: ${planId}`);
          this.sendMessage("join", { plan_id: planId });
        },
      },
      closeObserver: {
        next: (error: any) => {
          if (this.socketClosedPurposefully) {
            console.log("Socket connection closed purposefully.");
            return;
          }

          console.log("WebSocket connection closed.", error);
          this.disconnect(false); // Close the connection
          if (error.code === 1008 || error.code === 1006) {
            // Check if the error code is 1008 (invalid token)
            this.refreshToken().subscribe(() => {
              console.log("Token refreshed. Reconnecting...");
              this.handleReconnection(planId); // Retry connection after refreshing token
            });
          } else {
            this.handleReconnection(planId); // Handle reconnection for other errors
          }
        },
      },
    });

    // Subscribe to receive messages and pass them to the incomingMessages$ subject
    this.socket$.subscribe(
      (message) => this.incomingMessages$.next(message),
      (error) => console.error("WebSocket error:", error),
      () => console.log("WebSocket connection completed.")
    );

    // Subscribe to outgoingMessages$ to send messages through the WebSocket
    this.outgoingMessages$.subscribe((message) => {
      if (this.socket$ && !this.socket$.closed) {
        this.socket$.next(message);
        console.log("Message sent:", message);
      } else {
        console.warn("Cannot send message, WebSocket is closed.");
      }
    });
  }

  // Handle reconnection logic
  private handleReconnection(planId: string): void {
    let intervalMultiplier = Math.min(
      this.reconnectAttempts,
      this.maxReconnectAttempts
    );

    // Retry connection after a delay
    this.clearReconnectTimeout();
    const socketService = this;
    this.reconnectTimeOutEvent = setTimeout(function () {
      socketService.reconnectAttempts += 1;
      console.log(`Reconnection attempt #${socketService.reconnectAttempts}`);
      socketService.connect(planId, true); // Try to reconnect
    }, intervalMultiplier * this.reconnectInterval);
  }

  // Refresh token
  private refreshToken() {
    const refreshUrl = `${this.stateService.apiList.user.refreshToken}`;
    window.localStorage.c = window.localStorage.d;
    return this.http.postMethod(refreshUrl).pipe(
      tap((data: any) => {
        if (data.access_token) {
          // Update tokens
          window.localStorage.c = data.access_token;
          window.localStorage.d = data.refresh_token;

          // Arrive at new tokenExpiryDate
          this.stateService.setTokenExpiryDate();
        }
      }),
      catchError((error: any) => {
        console.error("Error refreshing token:", error);
        // If there is an exception calling 'refreshToken', bad news so logout.
        window.localStorage.clear();
        window.sessionStorage.clear();
        if (
          !this.router.url.includes("home") &&
          !this.router.url.includes("login")
        ) {
          window.location.replace(this.stateService.apiList.user.login);
        }
        return throwError("");
      })
    );
  }

  private clearReconnectTimeout() {
    if (this.reconnectTimeOutEvent) {
      clearTimeout(this.reconnectTimeOutEvent);
    }
  }

  // Disconnect the socket connection
  public disconnect(avoidReconnect: boolean = true): void {
    if (this.socket$) {
      this.socket$.complete();
      this.closeSubjects();
      this.socket$ = null;
    }

    if (avoidReconnect) {
      this.socketClosedPurposefully = true;
      this.clearReconnectTimeout();
      console.log("Reconnection attempts cleared if any.");
    }
  }

  // Send a message to the server
  public sendMessage(type: string, data: any): void {
    data.plan_id = this.planId;
    const message = {
      type,
      data,
    };
    this.outgoingMessages$.next(message);
  }

  // Observable to listen for incoming messages
  public onMessage(): Observable<any> {
    return this.incomingMessages$.asObservable();
  }
}
