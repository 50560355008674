export const EdaStatic = {
  trend_options: [
    {
      label: "Volume",
      value: "volume",
    },
    {
      label: "Revenue",
      value: "revenue",
    },
    {
      label: "MACO",
      value: "maco",
    },
  ],
  feature_options: [
    {
      label: "Segment",
      value: "ptc_segment",
    },
    {
      label: "Brand",
      value: "brand",
    },
    {
      label: "Mega Brand",
      value: "mega_brand",
    },
    {
      label: "Sub Brand",
      value: "sub_brand",
    },
    {
      label: "Pack Type",
      value: "pack_type",
    },
  ],
  eda_top_content: [
    {
      title: "No of SKUs",
      value: 0,
      value_type: "no_of_skus",
      icon: "icon-total-sku.svg",
      color_code: "#FFCA0033",
    },
    {
      title: "Total Volume",
      value: 0,
      value_type: "total_volume",
      icon: "icon-total-volume.svg",
      color_code: "#12A4ED33",
    },
    {
      title: "Total Revenue ($)",
      value: 0,
      value_type: "total_revenue",
      icon: "icon-total-revenue.svg",
      color_code: "#F5325C33",
    },
    {
      title: "Total Maco ($)",
      value: 0,
      value_type: "total_maco",
      icon: "icon-total-maco.svg",
      color_code: "#FF850033",
    },
    {
      title: "Total SCL ($)",
      value: 0,
      value_type: "total_scl",
      icon: "icon-total-scl.svg",
      color_code: "#7854F633",
    },
    {
      title: "Total VILC ($)",
      value: 0,
      value_type: "total_vilc",
      icon: "icon-total-vilc.svg",
      color_code: "#FFCA0033",
    },
  ],
  eda_left_box_content: [
    {
      title: "Brands",
      value: 0,
      value_type: "no_of_brand",
      icon: "icon-no-brand.svg",
    },
    {
      title: "Seasonal SKUs",
      value: 0,
      value_type: "no_of_seasonal_skus",
      icon: "icon-no-seasonal-sku.svg",
    },
    {
      title: "Sub Brands",
      value: 0,
      value_type: "no_of_subbrands",
      icon: "icon-no-sub-brand.svg",
    },
    {
      title: "Mega Brands",
      value: 0,
      value_type: "no_of_mega_brands",
      icon: "icon-no-mega-brand.svg",
    },
    {
      title: "SKU with Neg(-) MACO",
      value: 0,
      value_type: "no_of_sku_with_neg_maco",
      icon: "icon-no-sku-maco.svg",
    },
  ],
  sku_megabrand_funnel: [
    {
      no_of_sku: 50,
      volume: "12%",
      scale: "~50%",
      investment: "~60%",
    },
    {
      no_of_sku: 120,
      volume: "23%",
      scale: "1-5%",
      investment: "~10%",
    },
    {
      no_of_sku: 35,
      volume: "45%",
      scale: "~30%",
      investment: "~30%",
    },
    {
      no_of_sku: 58,
      volume: "40%",
      scale: "< 20%",
      investment: "No Investment",
    },
  ],
  date_limit: {
    min_date: "2023-01-01",
    max_date: "2024-02-20",
  },
  date_range: {
    start_date: "2023-02-01",
    end_date: "2024-01-20",
  },
  portfolio_efficiency_options: [
    {
      label: "MACO Cluster",
      value: "maco_cluster",
    }
    ,{
      label: "Segment",
      value: "ptc_segment",
    },
    {
      label: "Brand",
      value: "brand",
    },
    {
      label: "Pack  Type",
      value: "pack_type",
    },
  ],
  segment_analysis_filters: [
    {
      label: "Segment",
      value: "ptc_segment",
    },
    {
      label: "Brand",
      value: "brand",
    },
    {
      label: "Pack  Type",
      value: "pack_type",
    },
    {
      label: "MACO Cluster",
      value: "maco_cluster"
    }
  ],
  segment_analysis: [
    {
      label: "Total Skus",
      value: "total_sku",
    },
    {
      label: "MACO",
      value: "maco",
    },
    {
      label: "Maco Margin",
      value: "maco_margin",
    },
    {
      label: "Volume",
      value: "volume",
    },
    {
      label: "Net Revenue",
      value: "net_revenue",
    }
  ],
  segment_options: [
    {
      label: "MACO/HL",
      value: "maco_per_hl",
    },
    {
      label: "NR/HL",
      value: "nr_per_hl",
    },
    {
      label: "SCL/HL",
      value: "scl_per_hl",
    },
    {
      label: "MACO Margin",
      value: "maco_margin",
    },
  ],

  analysisTableCols: [
    {
      label: 'Brands',
      value: 'name',
      valueType: 'eda_name',
    },
    {
      label: 'Brand',
      value: 'obppc_category',
    },
    {
      label: 'Brand',
      value: 'brand',
    },
 
    {
      label: 'SKU Count',
      value: 'unique_sku_count',
    },
    {
      label: 'MACO Margin (%)',
      value: 'maco_margin',
    },
    {
      label: 'MACO/HL',
      value: 'maco_per_hl',
    },
    {
      label: 'Volume (%)',
      value: 'volume_perc',
    },
    {
      label: 'Revenue (%)',
      value: 'revenue_perc',
    },
    {
      label: 'SCL (%)',
      value: 'scl_perc',
    },
    {
      label: 'No of SKUs in Cluster D',
      value: 'tail_skus',
    }
  ],
  
  skuSubList: [
    {
      label: "Overall",
      value: "overall",
    },
    {
      label: "Tail SKUs",
      value: "tail_skus",
    },
    {
      label: "Negative MACO",
      value: "negative_maco",
    },
  ],
};